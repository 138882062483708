import FormOptionRequirementsModalService from './../form-option-requirements-modal-service'
import FormMicroserviceRequirements from '@/views/form/microservices/form-microservice-requirements.js'

export default {
  name: 'FormOptionRequirementsModalSingle',

  props: {
    formData: {
      type: Object,
      required: true,
    },

    requirement: {
      type: Object,
      required: true,
    },

    modelInformationData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      service: new FormOptionRequirementsModalService(
        this.modelInformationData
      ),
      requirementsService: new FormMicroserviceRequirements(),
      oldValue: [],
    }
  },

  async created() {
    this.updateForm()
  },

  methods: {
    updateForm() {
      const changes = this.getChanges()
      this.saveChangesToForm(changes)
      this.service.commitChanges(changes, this.oldValue)
    },

    saveChangesToForm(changes) {
      this.requirementsService.addApprovedRequirementsToForm(
        this.modelInformationData.components,
        this.formData,
        changes
      )
    },

    getChanges() {
      return this.service.getChanges(
        [
          {
            id: this.requirement.id,
            quantity: this.requirement.quantity,
          },
        ],
        this.oldValue
      )
    },
  },
}
