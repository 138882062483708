import FormOptionDetailsBox from '@/components/form/default/form-option-details-box/FormOptionDetailsBox'
import FormOptionDetailsBoxInfo from '@/components/form/default/form-option-details-box/form-option-details-box-info/FormOptionDetailsBoxInfo'
import FormOptionDetailsBoxService from '../form-option-details-box/form-option-details-box-service'
import FormListFilterService from '../form-list-filter-bar/form-list-filter-service'
import FormListFilterBar from '@/components/form/default/form-list-filter-bar/FormListFilterBar'
import FormListCollapsedBar from '@/components/form/default/form-list-collapsed-bar/FormListCollapsedBar'
import FormListHelper from '@/helpers/form-list-helper'
import { Decimal } from 'decimal.js'

export default {
  name: 'FormChooseCountableSingleOptionList',

  components: {
    FormOptionDetailsBox,
    FormOptionDetailsBoxInfo,
    FormListFilterBar,
    FormListCollapsedBar,
  },

  props: {
    value: {
      type: Array | null,
      required: true,
      description: 'Example: [{ id: 12, quantity: 12 }]',
    },

    attributes: {
      type: [] | null,
    },

    listName: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },

    isError: {
      type: Boolean,
      default: false,
    },

    isSubmittable: {
      type: Boolean,
      default: false,
    },

    image: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      optionDetailsService: new FormOptionDetailsBoxService(this.options),
      formFilterBarService: new FormListFilterService(),
      hiddenOptions: [],
      price: null,
      optionsData: this.copyObject(this.options),
      formListHelper: new FormListHelper(),
      isSubmitted: false,
    }
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.updateHiddenOptions()
      },
    },
  },

  async created() {
    this.formFilterBarService.setOptionsVisible(this.optionsData)
  },

  computed: {
    isOptionSelected() {
      return option => {
        return this.formListHelper.isOptionSelected(option, this.value)
      }
    },

    selected: {
      get() {
        if (this.isValueNotNull()) {
          this.setPrice(this.value[0].id)

          return this.value[0].id
        }

        return null
      },

      set(val) {
        if (this.isSelectedNotValidForEmit(val)) {
          return
        }

        this.emit(val, 0 < this.quantity ? this.quantity : 1)
      },
    },

    quantity: {
      get() {
        return this.isValueNotNull() ? this.value[0].quantity : 0
      },

      set(val) {
        if (this.isQuantityNotValidForEmit(val)) {
          return
        } else if (1 > val) {
          this.clearSelectedOption()

          return
        }

        this.emit(this.value[0].id, val)
      },
    },
  },

  methods: {
    isValueNotNull() {
      return this.value && this.value[0]
    },

    isSelectedNotValidForEmit(selected) {
      return (
        !selected || (this.isValueNotNull() && this.value[0].id === selected)
      )
    },

    isQuantityNotValidForEmit(quantity) {
      return (
        !this.selected ||
        (this.isValueNotNull() && this.value[0].quantity === quantity)
      )
    },

    emit(id, quantity) {
      this.$emit('input', [
        {
          id: id,
          quantity: quantity,
        },
      ])
    },

    setPrice(id) {
      const option = this.options.find(item => item.id === id)
      if (option) {
        this.price = new Decimal(option.price).toFixed(2)
      }
    },

    isSpinbuttonVisible(option) {
      if (
        this.value !== undefined &&
        this.value !== null &&
        this.value[0] !== undefined
      ) {
        return this.value[0].id == option.id
      }
    },

    clearSelectedOption() {
      this.$emit('input', [])
      this.formListHelper.updateHiddenOptions([])
    },

    filterOptions(event) {
      this.formFilterBarService.setOptionsVisibilty(this.optionsData, event)
      this.formListHelper.updateHiddenOptions(this.value)
      this.$forceUpdate()
    },

    updateHiddenOptions(value) {
      this.hiddenOptions = this.formFilterBarService.getHiddenSelectedOptions(
        this.optionsData,
        value
      )
    },

    toggleSubmitted() {
      this.isSubmitted = !this.isSubmitted

      if (!this.isSubmitted) {
        this.formListHelper.reloadHiddenOptions(
          this.hiddenOptions,
          this.optionsData,
          this.value
        )
      }

      this.$emit('submitted', {
        name: this.listName,
        isSubmitted: this.isSubmitted,
      })
      this.formListHelper.scrollToElementById(this.listName)
    },
  },
}
