import FormListFilterSelectMultiple from '@/components/form/default/form-list-filter-bar/form-list-filter-select-multiple/FormListFilterSelectMultiple'
import FormListFilterRange from '@/components/form/default/form-list-filter-bar/form-list-filter-range/FormListFilterRange'
import FormListHiddenOptionBar from '@/components/form/default/form-list-filter-bar/form-list-hidden-option-bar/FormListHiddenOptionBar'
import FormListFilterService from '../form-list-filter-bar/form-list-filter-service'
import ImageLoader from '@/components/shared/image-loader/ImageLoader'

export default {
  name: 'FormListFilterBar',

  components: {
    FormListFilterSelectMultiple,
    FormListHiddenOptionBar,
    FormListFilterRange,
    ImageLoader,
  },

  props: {
    attributes: {
      type: [] | null,
      required: true,
    },

    listName: {
      type: String,
      required: true,
    },

    hiddenOptions: {
      type: [] | null,
      required: true,
    },

    hiddenDeletable: {
      type: Boolean,
      default: true,
      required: false,
    },

    value: {
      type: [] | null,
      required: true,
    },

    image: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      showAllFilters: false,
      hideFilteringOptions: false,
      formFilterService: new FormListFilterService(),
      filterAttributes: [],
    }
  },

  computed: {
    isAdditionalFiltersButton() {
      return !this.hideFilteringOptions && this.isAdditionalFilterExists()
    },

    isShowAllButton() {
      return Array.isArray(this.attributes) && this.attributes.length > 0
    },

    isHiddenOptionsExists() {
      return this.hiddenOptions != null && this.hiddenOptions.length > 0
    },
  },

  methods: {
    isAdditionalFilterExists() {
      if (!Array.isArray(this.attributes)) {
        return false
      }

      for (let i = 0; i < this.attributes.length; i++) {
        if (this.attributes[i].isMain === false) {
          return true
        }
      }

      return false
    },

    isVisible(attribute, type) {
      if (this.showAllFilters === false) {
        return (
          attribute.isMain === true &&
          attribute.type === type &&
          attribute.isVisible
        )
      }

      return attribute.type === type && attribute.isVisible
    },

    toggleAllFilters() {
      if (this.showAllFilters) {
        this.filterAttributes.forEach(filter => {
          if (!filter.attribute.isMain) {
            filter.selected = ''
          }
        })
        this.emitFilter(this.filterAttributes)
      }
      this.showAllFilters = !this.showAllFilters
    },

    emitFilter(dataToEmit) {
      this.$emit('filter', dataToEmit)
    },

    clearAllFilters(value) {
      if (value) {
        this.filterAttributes.forEach(filter => {
          filter.selected = []
        })
      }

      this.emitFilter(this.filterAttributes)
    },

    onFilterSelect(event) {
      this.filterAttributes[event.attribute.id] = event
      this.emitFilter(this.filterAttributes)
    },

    emitDeleteOption(optionId) {
      this.$emit('deleteOption', optionId)
    },

    checkIfAttributesExists() {
      return this.attributes != undefined && this.attributes.length > 0
    },
  },
}
