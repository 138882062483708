export default {
  name: 'FormOptionDetailsBox',

  props: {
    option: {
      type: Object,
      require: true,
    },
  },

  methods: {
    emit() {
      this.$emit('option-details-toggle', this.option.id)
    },

    showAttention() {
      return (
        this.isListNotEmpty(this.option.onSelect) ||
        this.isListNotEmpty(this.option.requirements) ||
        this.option.weight > 1
      )
    },

    isListNotEmpty(list) {
      if (list === undefined) {
        return false
      }

      if (list.oneTime !== undefined) {
        return (
          this.isListExistsAndNotEmpty(list.oneTime.components) ||
          this.isListExistsAndNotEmpty(list.oneTime.componentsFromList)
        )
      }

      if (list.eachTime !== undefined) {
        return (
          this.isListExistsAndNotEmpty(list.eachTime.components) ||
          this.isListExistsAndNotEmpty(list.eachTime.componentsFromList)
        )
      }
    },

    isListExistsAndNotEmpty(list) {
      return list !== undefined && list.length > 0
    },
  },
}
