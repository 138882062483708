import ImageLoader from '@/components/shared/image-loader/ImageLoader'

export default {
  name: 'Icon',

  components: { ImageLoader },

  props: {
    img: {
      type: String,
      required: true,
    },

    content: {
      type: String,
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  methods: {},
}
