const ATTRIBUTES = 'attributes-'

export default {
  name: 'FormOptionDetailsCollapseAttributes',

  props: {
    option: {
      type: Object,
      require: true,
    },
  },

  methods: {
    getToggleId() {
      return ATTRIBUTES + this.option.id
    },

    getAttributes() {
      return this.option.attributes
    },
  },
}
