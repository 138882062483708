import LoadingOverlayGear from '@/components/shared/loading-overlay/loading-overlay-gear/LoadingOverlayGear.vue'

export default {
  name: 'LoadingOverlay',

  components: {
    LoadingOverlayGear,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },

    error: {
      type: Boolean,
      required: false,
      default: false,
    },

    msg: {
      type: String,
      default: 'shared.loading',
    },
  },
}
