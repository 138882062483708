import ImageLoader from '@/components/shared/image-loader/ImageLoader'

export default {
  name: 'FormSummaryModalButton',

  components: {
    ImageLoader,
  },

  props: {
    modalData: {
      type: Object,
      required: true,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      statusClass: '',
    }
  },

  watch: {
    modalData: {
      deep: true,
      handler() {
        this.statusClass = this.modalData.modalStatus
      },
    },

    isDisabled: {
      handler(value) {
        if (value) {
          this.statusClass = 'blocked'
        } else {
          this.statusClass = this.modalData.modalStatus
        }
      },
    },
  },

  methods: {
    openModal(isDisabled) {
      if (isDisabled) {
        return
      }

      this.$bvModal.show('form-summary-modal-form-modal')
      this.$emit('summaryModal', this.modalData)
    },
  },
}
