import { Decimal } from 'decimal.js'
import FormListFilterService from './../components/form/default/form-list-filter-bar/form-list-filter-service'

export default class FormListHelper {
  filterService = new FormListFilterService()

  scrollToElementById(id) {
    const element = document.getElementById(id)

    if (!element) {
      return
    }

    const scrollY = element.getBoundingClientRect().top + window.scrollY - 100
    window.scrollTo({ top: scrollY, behavior: 'smooth' })
  }

  printPrice(price) {
    const char = new Decimal(price).greaterThan(0) ? '+' : ''
    return char + price
  }

  getListClass(isError, isSubmitted) {
    let domClass = ''

    if (isError) {
      domClass += 'border-error'

      if (isSubmitted) {
        domClass += ' bg-error'
      }
    } else {
      if (isSubmitted) {
        domClass += 'border-success bg-success'
      }
    }

    return domClass
  }

  reloadHiddenOptions(hiddenOptions, optionsData, value) {
    this.filterService.setOptionsVisible(optionsData)
    this.updateHiddenOptions(hiddenOptions, optionsData, value)
  }

  updateHiddenOptions(hiddenOptions, optionsData, value) {
    hiddenOptions = this.filterService.getHiddenSelectedOptions(
      optionsData,
      value
    )
  }

  isOptionSelected(option, value) {
    if (!Array.isArray(value)) {
      return false
    }

    for (let i = 0; i < value.length; i++) {
      if (value[i].id === option.id && value[i].quantity > 0) {
        return true
      }
    }
    return false
  }
}
