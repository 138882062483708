export default {
  name: 'FormListHiddenOption',

  props: {
    hiddenOption: {
      type: Object,
      required: true,
    },

    hiddenDeletable: {
      type: Boolean,
      default: true,
      required: false,
    },
  },

  methods: {
    emitDeleteOption() {
      this.$emit('deleteOption', this.hiddenOption.id)
    },
  },
}
