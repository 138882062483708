export default class FormMicroserviceGetChanges {
  getFormChanges(oldForm, lastListInput) {
    const newSelection = _getNewSelection(oldForm, lastListInput)
    if (newSelection) {
      return newSelection
    }

    const removedSelection = _getRemovedSelection(oldForm, lastListInput)
    if (removedSelection) {
      return removedSelection
    }

    return _getSelectionChanges(oldForm, lastListInput)
  }
}

function _getNewSelection(oldForm, lastListInput) {
  if (!oldForm[lastListInput.componentName]) {
    return lastListInput.options
  }

  return null
}

function _getRemovedSelection(oldForm, lastListInput) {
  if (0 === lastListInput.options.length) {
    const change = []
    oldForm[lastListInput.componentName].forEach(oldOption => {
      change.push({
        id: oldOption.id,
        quantity: -oldOption.quantity,
      })
    })

    return change
  }

  return null
}

function _getSelectionChanges(oldForm, lastListInput) {
  const change = []

  lastListInput.options.forEach(newOption => {
    const oldOption = oldForm[lastListInput.componentName].find(
      option => option.id === newOption.id
    )
    if (!oldOption) {
      change.push({
        id: newOption.id,
        quantity: newOption.quantity,
      })
    } else if (oldOption.quantity !== newOption.quantity) {
      change.push({
        id: newOption.id,
        quantity: newOption.quantity - oldOption.quantity,
      })
    }
  })

  oldForm[lastListInput.componentName].forEach(oldOption => {
    const newOption = lastListInput.options.find(
      option => option.id === oldOption.id
    )
    if (!newOption) {
      change.push({
        id: oldOption.id,
        quantity: -oldOption.quantity,
      })
    }
  })

  return change
}
