export default class FormSummaryModalService {
  getAttributesToShow(attributes, attributesDefinitions) {
    const attributesToShow = []

    if (!attributes || !attributesDefinitions) {
      return attributesToShow
    }

    attributes.forEach(attribute => {
      const definition = attributesDefinitions.find(x => x.id === attribute.id)
      if (definition && definition.isVisible) {
        attributesToShow.push(attribute)
      }
    })

    return attributesToShow
  }
}
