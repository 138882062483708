import FormSummaryModalComponent from '@/components/form/default/form-summary-modal/form-summary-modal-component/FormSummaryModalComponent'
import FormSummaryModalButton from '@/components/form/default/form-summary-modal/form-summary-modal-button/FormSummaryModalButton'
import ServerTotalPriceAndInfo from '@/components/shared/server-total-price-and-info/ServerTotalPriceAndInfo'
import ImageLoader from '@/components/shared/image-loader/ImageLoader'
import FormSummaryModalService from './form-summary-modal-service'

export default {
  name: 'FormSummaryModal',

  components: {
    FormSummaryModalComponent,
    FormSummaryModalButton,
    ServerTotalPriceAndInfo,
    ImageLoader,
  },

  props: {
    invalidFeedbacks: {
      type: Object,
      required: true,
    },

    formCurrent: {
      type: Object,
      required: true,
    },

    components: {
      type: Array,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },

    modelName: {
      type: String,
      required: true,
    },

    seriesName: {
      type: String,
      required: false,
    },

    modelImage: {
      type: String,
      required: false,
    },

    producerLogo: {
      type: String,
      required: false,
    },

    totalPriceNet: {
      type: String,
      required: false,
    },

    totalPriceGross: {
      type: String,
      required: false,
    },

    modalsData: {
      type: Object,
      required: true,
    },

    contactData: {
      type: Object,
    },
  },

  data() {
    return {
      formSummaryModalService: new FormSummaryModalService(),
    }
  },

  methods: {
    closeModal() {
      this.$bvModal.hide('form-summary-modal')
    },

    getComponentData(option, name) {
      let data = {
        attributes: [],
        price: '',
        model: '',
      }

      for (let i = 0; i < this.components.length; i++) {
        if (this.components[i].name === name) {
          let componentList = this.components[i].list

          for (let j = 0; j < componentList.length; j++) {
            if (componentList[j].id === option.id) {
              data.attributes = this.formSummaryModalService.getAttributesToShow(
                componentList[j].attributes,
                this.components[i].attributes
              )
              data.price = componentList[j].price
              data.model = componentList[j].name
              data.image = this.components[i].image
              return data
            }
          }
        }
      }

      return data
    },

    emitSummaryModal(modalData) {
      this.$emit('summaryModal', modalData)
    },
  },
}
