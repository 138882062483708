import FormOptionDetailsBox from '@/components/form/default/form-option-details-box/FormOptionDetailsBox'
import FormOptionDetailsBoxInfo from '@/components/form/default/form-option-details-box/form-option-details-box-info/FormOptionDetailsBoxInfo'
import FormOptionDetailsBoxService from '../form-option-details-box/form-option-details-box-service'
import FormListFilterService from '../form-list-filter-bar/form-list-filter-service'
import FormListFilterBar from '@/components/form/default/form-list-filter-bar/FormListFilterBar'
import FormListCollapsedBar from '@/components/form/default/form-list-collapsed-bar/FormListCollapsedBar'
import FormListHelper from '@/helpers/form-list-helper'

export default {
  name: 'FormChooseMultipleOptionList',

  components: {
    FormOptionDetailsBox,
    FormOptionDetailsBoxInfo,
    FormListFilterBar,
    FormListCollapsedBar,
  },

  props: {
    value: {
      type: Array | null,
      required: true,
    },

    attributes: {
      type: [] | null,
    },

    listName: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },

    isError: {
      type: Boolean,
      default: false,
    },

    isSubmittable: {
      type: Boolean,
      default: false,
    },

    image: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      optionsData: this.copyObject(this.options),
      optionDetailsService: new FormOptionDetailsBoxService(this.options),
      formFilterBarService: new FormListFilterService(),
      formListHelper: new FormListHelper(),
      hiddenOptions: [],
      isSubmitted: false,
    }
  },

  async created() {
    this.formFilterBarService.setOptionsVisible(this.optionsData)
  },

  computed: {
    isOptionSelected() {
      return option => {
        return this.formListHelper.isOptionSelected(option, this.value)
      }
    },

    selected: {
      get() {
        const data = []
        if (Array.isArray(this.value)) {
          this.value.forEach(item => {
            data.push(item.id)
          })
        }

        return data
      },

      set(val) {
        if (this.selected === val) {
          return
        }

        const emit = []
        val.forEach(item => emit.push({ id: item, quantity: 1 }))
        this.$emit('input', 0 < emit.length ? emit : [])
      },
    },
  },

  methods: {
    filterOptions(event) {
      this.formFilterBarService.setOptionsVisibilty(this.optionsData, event)
      this.formListHelper.updateHiddenOptions(this.value)
      this.$forceUpdate()
    },

    updateHiddenOptions(value) {
      this.hiddenOptions = this.formFilterBarService.getHiddenSelectedOptions(
        this.optionsData,
        value
      )
    },

    clearSelectedOption(optionId) {
      let emit = []

      this.selected.forEach(item => {
        if (item !== optionId) {
          emit.push({ id: item, quantity: 1 })
        }
      })

      this.$emit('input', emit)
      this.updateHiddenOptions(emit)
    },

    toggleSubmitted() {
      this.isSubmitted = !this.isSubmitted

      if (!this.isSubmitted) {
        this.formListHelper.reloadHiddenOptions(
          this.hiddenOptions,
          this.optionsData,
          this.value
        )
      }

      this.$emit('submitted', {
        name: this.listName,
        isSubmitted: this.isSubmitted,
      })
      this.formListHelper.scrollToElementById(this.listName)
    },
  },
}
