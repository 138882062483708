export default {
  name: 'LoadingOverlayGear',

  props: {
    isError: {
      type: Boolean,
      default: false,
    },
  },
}
