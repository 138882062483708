export default class FormMicroserviceAbstract {
  constructor() {
    if (this.constructor == FormMicroserviceAbstract) {
      throw new Error("Abstract class can't be instantiated.")
    }
  }

  findOptionWithComponentName(components, id) {
    const data = {
      componentName: undefined,
      option: undefined,
    }

    for (let i = 0; i < components.length; i++) {
      data.option = components[i].list.find(item => item.id === id)
      if (data.option) {
        data.componentName = components[i].name

        break
      }
    }

    return data
  }

  copyObject(object) {
    return JSON.parse(JSON.stringify(object))
  }
}
