import { Subject, ReplaySubject } from 'rxjs'

const subjectTitle = new Subject()
const subjectModalsData = new Subject()
const subjectCurrentModal = new Subject()
const subjectContactData = new ReplaySubject()

export const headerRxjsHelper = {
  setTitle: title => subjectTitle.next(title),
  getTitle: () => subjectTitle.asObservable(),
  setModalsData: modalsData => subjectModalsData.next(modalsData),
  getModalsData: () => subjectModalsData.asObservable(),
  setCurrentModal: currentModal => subjectCurrentModal.next(currentModal),
  getCurrentModal: () => subjectCurrentModal.asObservable(),
  setContactData: contactData => subjectContactData.next(contactData),
  getContactData: () => subjectContactData.asObservable(),
}
