import FormSummaryModalFormModalService from '@/components/form/default/form-summary-modal/form-summary-modal-form-modal/form-summary-modal-form-modal-service'
import Icon from '@/components/shared/icon/Icon.vue'

export default {
  name: 'FormSummaryBar',

  components: {
    Icon,
  },

  props: {
    currency: {
      type: String,
      required: true,
    },

    totalPriceNet: {
      type: String,
      required: true,
    },

    modalsData: {
      type: Object,
      required: true,
    },
  },

  created() {
    window.addEventListener('scroll', this.handleScroll)
  },

  data() {
    return {
      isScrolledDown: false,
      isScrolledUp: false,
      summaryModalFormModalService: new FormSummaryModalFormModalService(this),
    }
  },

  methods: {
    handleScroll() {
      const scrollMaxY =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight
      this.isScrolledDown = scrollMaxY - 10 <= window.scrollY
      this.isScrolledUp = window.scrollY === 0
    },

    isBarVisible() {
      return !this.isScrolledUp || (this.isScrolledDown && this.isScrolledUp)
    },

    showSummaryModal() {
      this.$bvModal.show('form-summary-modal')
    },
  },
}
