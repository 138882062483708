import FormListHiddenOption from '@/components/form/default/form-list-filter-bar/form-list-hidden-option-bar/form-list-hidden-option/FormListHiddenOption'

export default {
  name: 'FormListHiddenOptionBar',

  components: {
    FormListHiddenOption,
  },

  props: {
    hiddenOptions: {
      type: [] | null,
      required: true,
    },

    hiddenDeletable: {
      type: Boolean,
      default: true,
      required: false,
    },
  },

  methods: {
    checkIfHiddenOptionsExists() {
      return this.hiddenOptions != null && this.hiddenOptions.length > 0
    },

    emitDeleteOption(optionId) {
      this.$emit('deleteOption', optionId)
    },
  },
}
