import FormOptionDetailsBox from '@/components/form/default/form-option-details-box/FormOptionDetailsBox'
import FormOptionDetailsBoxInfo from '@/components/form/default/form-option-details-box/form-option-details-box-info/FormOptionDetailsBoxInfo'
import FormOptionDetailsBoxService from '../form-option-details-box/form-option-details-box-service'
import FormListFilterService from '../form-list-filter-bar/form-list-filter-service'
import FormListFilterBar from '@/components/form/default/form-list-filter-bar/FormListFilterBar'
import FormListCollapsedBar from '@/components/form/default/form-list-collapsed-bar/FormListCollapsedBar'
import FormListHelper from '@/helpers/form-list-helper'
import { Decimal } from 'decimal.js'

export default {
  name: 'FormChooseSingleOptionList',

  components: {
    FormOptionDetailsBox,
    FormOptionDetailsBoxInfo,
    FormListFilterBar,
    FormListCollapsedBar,
  },

  props: {
    value: {
      type: Array | null,
      required: true,
    },

    attributes: {
      type: [] | null,
    },

    listName: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },

    isError: {
      type: Boolean,
      default: false,
    },

    isSubmittable: {
      type: Boolean,
      default: false,
    },

    image: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      optionsData: this.copyObject(this.options),
      optionDetailsService: new FormOptionDetailsBoxService(this.options),
      formFilterBarService: new FormListFilterService(),
      formListHelper: new FormListHelper(),
      price: 0,
      hiddenOptions: [],
      isSubmitted: false,
    }
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.formListHelper.updateHiddenOptions()
      },
    },
  },

  async created() {
    this.formFilterBarService.setOptionsVisible(this.optionsData)
  },

  computed: {
    isOptionSelected() {
      return option => {
        if (!Array.isArray(this.value)) {
          return false
        }

        for (let i = 0; i < this.value.length; i++) {
          if (this.value[i].id === option.id && this.value[i].quantity > 0) {
            return true
          }
        }
        return false
      }
    },

    selected: {
      get() {
        if (this.value && this.value[0]) {
          this.recalcualtePrice(this.value[0].id)

          return this.value[0].id
        }

        return null
      },

      set(val) {
        if (!this.value || !this.value[0] || val !== this.value[0].id) {
          this.recalcualtePrice(val)

          if (val) {
            this.$emit('input', [{ id: val, quantity: 1 }])
          }
        }
      },
    },
  },

  methods: {
    recalcualtePrice(val) {
      if (!val) {
        this.optionsData = this.copyObject(this.options)

        return
      }

      const selectedOption = this.optionsData.find(x => x.id === val)
      if (!selectedOption) {
        return
      }

      let currentPrice = selectedOption.price ? selectedOption.price : 0
      currentPrice = new Decimal(currentPrice)
      if (!currentPrice.equals(0)) {
        this.optionsData.forEach(
          i => (i.price = new Decimal(i.price).minus(currentPrice).toFixed(2))
        )
        this.price = new Decimal(this.price).plus(currentPrice).toFixed(2)
      }
    },

    filterOptions(event) {
      this.formFilterBarService.setOptionsVisibilty(this.optionsData, event)
      this.formListHelper.updateHiddenOptions(this.value)
      this.$forceUpdate()
    },

    toggleSubmitted() {
      this.isSubmitted = !this.isSubmitted

      if (!this.isSubmitted) {
        this.formListHelper.reloadHiddenOptions(
          this.hiddenOptions,
          this.optionsData,
          this.value
        )
      }

      this.$emit('submitted', {
        name: this.listName,
        isSubmitted: this.isSubmitted,
      })
      this.formListHelper.scrollToElementById(this.listName)
    },
  },
}
