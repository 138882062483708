export default class FormOptionDetailsBoxService {
  collapsed = []

  constructor(options) {
    options.forEach(option => {
      this.collapsed[option.id] = false
    })
  }

  toggleOptionDetails(option) {
    if (option) {
      this.collapsed[option] = !this.collapsed[option]
      this.collapsed.push('')
      this.collapsed.pop()
    }
  }

  getOptionById(id, options) {
    if (options && id) {
      return options.find(obj => obj.id === id)
    }
  }

  getCollapsed() {
    return this.collapsed
  }

  getCurrentQuantity(id, data) {
    if (Array.isArray(data) && id) {
      const result = data.find(val => val.id === id)
      if (result && result.quantity) {
        return result.quantity
      }
      return 0
    }
  }
}
