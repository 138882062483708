export default class FormListFilterService {
  setOptionsVisible(options) {
    if (options.length === 0) {
      return
    }

    options.forEach(option => {
      option.isVisible = true
    })
  }

  setOptionsVisibilty(options, filters) {
    this.setOptionsVisible(options)

    if (filters.length === 0) {
      return
    }

    options.forEach(option => {
      filters.forEach(filter => {
        if (
          Array.isArray(filter.selected) &&
          filter.selected.length > 0 &&
          !_checkIfOptionAllowedByFilters(
            option.attributes,
            filter,
            filter.attribute.type
          )
        ) {
          option.isVisible = false
        }
      })
    })
  }

  getHiddenSelectedOptions(options, selectedOptions) {
    var hiddenOptions = []

    if (options == undefined || selectedOptions == undefined) {
      return hiddenOptions
    }

    options.forEach(option => {
      if (option.isVisible) {
        return
      }

      selectedOptions.forEach(selectedOption => {
        if (selectedOption.quantity === 0 || selectedOption.id !== option.id) {
          return
        }

        hiddenOptions.push({
          id: option.id,
          quantity: selectedOption.quantity,
          name: option.name,
        })
      })
    })

    return hiddenOptions
  }
}

function _checkIfOptionAllowedByFilters(attributes, filter, type) {
  return (
    (_checkIfContains(attributes, filter) && type === 'select') ||
    (_checkIfInRange(attributes, filter) && type === 'range')
  )
}

function _checkIfContains(attributes, filter) {
  for (var i = 0; i < attributes.length; i++) {
    for (var j = 0; j < filter.selected.length; j++) {
      if (
        attributes[i].id === filter.attribute.id &&
        attributes[i].value != null &&
        attributes[i].value.toString().toLowerCase() ===
          filter.selected[j].toString().toLowerCase()
      ) {
        return true
      }
    }
  }

  return false
}

function _checkIfInRange(attributes, filter) {
  for (var i = 0; i < attributes.length; i++) {
    if (
      attributes[i].id == filter.attribute.id &&
      attributes[i].value != null &&
      attributes[i].value >= filter.selected[0] &&
      attributes[i].value <= filter.selected[1]
    ) {
      return true
    }
  }

  return false
}
