import FormListHelper from '@/helpers/form-list-helper'

export default {
  name: 'FormListInformationPanel',

  props: {
    components: {
      type: Array,
      required: true,
    },

    invalidFeedbacks: {
      type: Object,
      required: true,
    },

    submittedLists: {
      type: Object,
      required: true,
    },
  },

  watch: {
    invalidFeedbacks: {
      deep: true,
      handler() {
        this.formData = this.getFormData()
      },
    },

    submittedLists: {
      deep: true,
      handler() {
        this.formData = this.getFormData()
      },
    },
  },

  data() {
    return {
      formData: this.getFormData(),
      formListHelper: new FormListHelper(),
      displayedList: '',
    }
  },

  computed: {
    isError() {
      return errors => {
        return Array.isArray(errors) && errors.length > 0
      }
    },

    isSuccess() {
      return list => {
        return !this.isError(list.name) && list.isSubmitted
      }
    },

    isDisplayed() {
      return name => {
        return name === this.displayedList
      }
    },
  },

  created() {
    window.addEventListener('scroll', () => {
      this.handleScroll()
    })
  },

  destroyed() {
    window.removeEventListener('scroll', () => {
      this.handleScroll()
    })
  },

  mounted() {
    this.$nextTick(() => {
      this.handleScroll(true)
    })
  },

  methods: {
    handleScroll(isInitial = false) {
      for (let i = 0; i < this.components.length; i++) {
        const element = document.getElementById(this.components[i].name)

        if (!element) {
          return
        }

        const componentPositionTop = element.getBoundingClientRect().top

        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight

        if (bottomOfWindow) {
          this.displayedList = this.components[this.components.length - 1].name
          return
        }

        if (
          componentPositionTop > 0 &&
          (componentPositionTop < 250 || isInitial)
        ) {
          this.displayedList = this.components[i].name
          return
        }
      }
    },

    getFormData() {
      let formData = {}

      this.components.forEach(component => {
        formData[component.name] = {
          name: component.name,
          isSubmitted: this.isSubmitted(component.name),
          errors: this.getErrors(component.name),
        }
      })

      return formData
    },

    isSubmitted(listName) {
      return (
        this.submittedLists[listName] != undefined &&
        this.submittedLists[listName]
      )
    },

    getErrors(listName) {
      let errors = []

      if (
        Array.isArray(this.invalidFeedbacks[listName]) &&
        this.invalidFeedbacks[listName].length > 0
      ) {
        this.invalidFeedbacks[listName].forEach(error => {
          errors.push(error)
        })
      }

      return errors
    },

    areSolutionsFounded(error) {
      return (
        error.findSolutions && error.solutions && error.solutions.length > 0
      )
    },

    openSolutionsModal(error) {
      this.$emit('solutionsModal', error)
    },

    isSearchingForSolutions(error) {
      return error.findSolutions && undefined === error.solutions
    },
  },
}
