import FormService from '@/views/form/form-service'

export const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
export const REGEX_PHONE = /(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/
export const REGEX_NIP = /^[0-9]{10}$/

export default class FormSummaryModalFormModalService {
  constructor(context) {
    this.formService = new FormService()
    this.context = context
  }

  get modalsData() {
    return modalsData
  }

  updateModalsDataGuard(modalsData, formCurrent, invalidFeedbacks) {
    let isValidated = false

    if (!formCurrent || !invalidFeedbacks) {
      return
    }

    isValidated = !this.formService.invalidFeedbacksExists(invalidFeedbacks)

    modalsData.saveConfigurationModal.isDisabled = !isValidated
    modalsData.requestSpecialPriceModal.isDisabled = !isValidated
    modalsData.requestOptimizationModal.isDisabled = false
  }

  showModal(modalData) {
    if (modalData.isDisabled) {
      return
    }

    this.context.$bvModal.show('form-summary-modal-form-modal')
    this.context.$emit('summaryModal', modalData)
  }
}

const modalFields = {
  email: {
    name: 'email',
    type: 'input',
    label: 'form.summary-modal.email',
    classes: ['required', 'font-bold'],
    required: true,
    validationRegEx: REGEX_EMAIL,
    regExMessage: 'form.summary-modal.invalid-email',
  },
  phoneNumber: {
    name: 'phoneNumber',
    type: 'input',
    label: 'form.summary-modal.phone',
    classes: ['font-bold'],
    validationRegEx: REGEX_PHONE,
    regExMessage: 'form.summary-modal.invalid-phone',
  },
  companyName: {
    name: 'companyName',
    type: 'input',
    label: 'form.summary-modal.company-name',
    classes: ['font-light'],
  },
  nip: {
    name: 'nip',
    type: 'input',
    label: 'form.summary-modal.nip',
    validationRegEx: REGEX_NIP,
    classes: ['font-bold', 'required'],
    required: true,
    info: 'form.summary-modal.why-we-require-nip',
  },
  termsAndPolicy: {
    name: 'termsAndPolicy',
    type: 'checkbox',
    label: 'form.summary-modal.accept-terms-and-policy',
    labelType: 'html',
    labelParams: {
      terms: 'https://dell24.pl/regulamin',
      policy: 'https://netland.pl/polityka-prywatnosci',
    },
    classes: ['required'],
    required: true,
    isIgnoredInRequest: true,
  },
  allowContact: {
    name: 'allowContact',
    type: 'checkbox',
    label: 'form.summary-modal.allow-for-contact',
  },
  furtherSaleProduct: {
    name: 'furtherSaleProduct',
    type: 'checkbox',
    label: 'form.summary-modal.further-sale-product',
  },
  getLinkToConfiguration: {
    name: 'getLinkToConfiguration',
    type: 'checkbox',
    classes: ['required'],
    required: true,
    value: true,
    label: 'form.summary-modal.get-link-to-saved-configuration',
  },
  downloadPdf: {
    name: 'downloadPdf',
    type: 'checkbox',
    label: 'form.summary-modal.get-pdf-file',
  },
  pdfTermsAndPolicy: {
    conditionalId: 'downloadPdf',
    name: 'pdfTermsAndPolicy',
    type: 'checkbox',
    label: 'form.summary-modal.accept-terms-and-policy',
    labelType: 'html',
    labelParams: {
      terms: 'https://dell24.pl/regulamin',
      policy: 'https://netland.pl/polityka-prywatnosci',
    },
    required: true,
    classes: ['required'],
    isIgnoredInRequest: true,
  },
  pdfEmail: {
    conditionalId: 'downloadPdf',
    name: 'pdfEmail',
    type: 'input',
    label: 'form.summary-modal.email',
    classes: ['required', 'font-bold'],
    required: true,
    validationRegEx: REGEX_EMAIL,
    regExMessage: 'form.summary-modal.invalid-email',
  },
  finalContractorNip: {
    conditionalId: 'furtherSaleProduct',
    name: 'finalContractorNip',
    type: 'input',
    label: 'form.summary-modal.final-contractor-nip',
    validationRegEx: REGEX_NIP,
    classes: ['font-light', 'required'],
    required: true,
    info: 'form.summary-modal.why-we-require-nip',
  },
}

const modalsData = {
  saveConfigurationModal: {
    label: 'form.summary-modal.save-configuration',
    image: 'images/icons/save.png',
    header: 'form.summary-modal.save-configuration',
    message: 'form.summary-modal.fill-form-to-save-configuration',
    requestButton: 'form.summary-modal.save',
    postType: 'save',
    modalFields: {
      nip: modalFields.nip,
      getLinkToConfiguration: modalFields.getLinkToConfiguration,
      downloadPdf: modalFields.downloadPdf,
      pdfEmail: modalFields.pdfEmail,
      pdfTermsAndPolicy: modalFields.pdfTermsAndPolicy,
    },
  },
  requestSpecialPriceModal: {
    label: 'form.summary-modal.ask-for-special-price',
    image: 'images/buttons/summary-price.png',
    header: 'form.summary-modal.fill-data-for-special-price',
    message: 'form.summary-modal.consultant-feedback',
    postType: 'special-price',
    modalFields: {
      nip: modalFields.nip,
      finalContractorNip: modalFields.finalContractorNip,
      email: modalFields.email,
      phoneNumber: modalFields.phoneNumber,
      termsAndPolicy: modalFields.termsAndPolicy,
      furtherSaleProduct: modalFields.furtherSaleProduct,
    },
  },
  requestOptimizationModal: {
    label: 'form.summary-modal.ask-for-optimization',
    image: 'images/buttons/summary-optimization.png',
    header: 'form.summary-modal.fill-data-for-optimization',
    message: 'form.summary-modal.consultant-feedback',
    postType: 'optimization',
    modalFields: {
      nip: modalFields.nip,
      email: modalFields.email,
      phoneNumber: modalFields.phoneNumber,
      termsAndPolicy: modalFields.termsAndPolicy,
    },
  },
}
