import ServerTotalPriceAndInfo from '@/components/shared/server-total-price-and-info/ServerTotalPriceAndInfo'
import FormSummaryModalFormModalService from '@/components/form/default/form-summary-modal/form-summary-modal-form-modal/form-summary-modal-form-modal-service'

export default {
  name: 'FormSummaryPanel',

  components: {
    ServerTotalPriceAndInfo,
  },

  props: {
    currency: {
      type: String,
      required: true,
    },

    modalsData: {
      type: Object,
      required: true,
    },

    modelName: {
      type: String,
      required: false,
    },

    seriesName: {
      type: String,
      required: false,
    },

    modelImage: {
      type: String,
      required: false,
    },

    producerLogo: {
      type: String,
      required: false,
    },

    totalPriceNet: {
      type: String,
      required: false,
    },

    totalPriceGross: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      summaryModalFormModalService: new FormSummaryModalFormModalService(this),
    }
  },

  methods: {
    showSummaryModal() {
      this.$bvModal.show('form-summary-modal')
    },
  },
}
