export const ITER_TYPE_ONE_TIME = 'oneTime'
export const ITER_TYPE_EACH_TIME = 'eachTime'
export const COMP_TYPE_ON_SELECT = 'on-select'
export const COMP_TYPE_REQUIREMENTS = 'requirements'

export default {
  name: 'FormOptionDetailsCollapse',

  props: {
    option: {
      type: Object,
      require: true,
    },

    componentType: {
      type: String,
      require: true,
    },

    iterationType: {
      type: String,
      require: true,
    },
  },

  data() {
    return {
      COMP_TYPE_ON_SELECT,
      COMP_TYPE_REQUIREMENTS,
      ITER_TYPE_ONE_TIME,
      ITER_TYPE_EACH_TIME,
      COLL_TYPE_ON_SELECT_ONE_TIME: 'on-select-one-time',
      COLL_TYPE_ON_SELECT_EACH_TIME: 'on-select-each-time',
      COLL_TYPE_REQUIREMENTS_ONE_TIME: 'rquirements-one-time',
      COLL_TYPE_REQUIREMENTS_EACH_TIME: 'requirements-each-time',
      COMPONENT: 'component',
      LIST: 'list',
      WEIGHT_GROUP: 'weightGroup',
      collapseType: '',
    }
  },

  created() {
    this.collapseType = this.getCollapseType()
  },

  methods: {
    getCollapseType() {
      if (
        this.componentType === this.COMP_TYPE_ON_SELECT &&
        this.iterationType === this.ITER_TYPE_ONE_TIME
      ) {
        return this.COLL_TYPE_ON_SELECT_ONE_TIME
      } else if (
        this.componentType === this.COMP_TYPE_ON_SELECT &&
        this.iterationType === this.ITER_TYPE_EACH_TIME
      ) {
        return this.COLL_TYPE_ON_SELECT_EACH_TIME
      } else if (
        this.componentType === this.COMP_TYPE_REQUIREMENTS &&
        this.iterationType === this.ITER_TYPE_ONE_TIME
      ) {
        return this.COLL_TYPE_REQUIREMENTS_ONE_TIME
      } else if (
        this.componentType === this.COMP_TYPE_REQUIREMENTS &&
        this.iterationType === this.ITER_TYPE_EACH_TIME
      ) {
        return this.COLL_TYPE_REQUIREMENTS_EACH_TIME
      }
    },

    getOptionAdditionalInfo() {
      switch (this.collapseType) {
        case this.COLL_TYPE_ON_SELECT_ONE_TIME:
          return this.getOneTimeOnSelects()
        case this.COLL_TYPE_ON_SELECT_EACH_TIME:
          return this.getEachTimeOnSelects()
        case this.COLL_TYPE_REQUIREMENTS_ONE_TIME:
          return this.getOneTimeRequirements()
        default:
          return this.getEachTimeRequirements()
      }
    },

    getButtonText() {
      switch (this.collapseType) {
        case this.COLL_TYPE_ON_SELECT_ONE_TIME:
          return 'form.option-details.on-select-one-time'
        case this.COLL_TYPE_ON_SELECT_EACH_TIME:
          return 'form.option-details.on-select-each-time'
        case this.COLL_TYPE_REQUIREMENTS_ONE_TIME:
          return 'form.option-details.requirements-one-time'
        default:
          return 'form.option-details.requirements-each-time'
      }
    },

    getInitialWord() {
      if (
        this.collapseType === this.COLL_TYPE_ON_SELECT_ONE_TIME ||
        this.collapseType === this.COLL_TYPE_ON_SELECT_EACH_TIME
      ) {
        return 'form.option-details.on-select'
      } else {
        return 'form.option-details.required'
      }
    },

    getSecondaryWord(type) {
      if (
        this.collapseType === this.COLL_TYPE_REQUIREMENTS_ONE_TIME ||
        this.collapseType === this.COLL_TYPE_REQUIREMENTS_EACH_TIME
      ) {
        return 'form.option-details.in-quantity'
      } else {
        if (type === this.COMPONENT) {
          return 'form.option-details.increase-weight'
        } else {
          return 'form.option-details.increase-weigh-group-cap'
        }
      }
    },

    getToggleId() {
      return (
        this.componentType + '-' + this.iterationType + '-' + this.option.id
      )
    },

    getOneTimeRequirements() {
      return this.getRequirementsData(this.option.requirements.oneTime)
    },

    getEachTimeRequirements() {
      return this.getRequirementsData(this.option.requirements.eachTime)
    },

    getRequirementsData(requirements) {
      let result = []

      if (requirements.components) {
        requirements.components.forEach(component => {
          result.push({
            name: component.name,
            type: this.COMPONENT,
            quantity: this.getQuantity(component),
          })
        })
      }

      if (requirements.componentsFromList) {
        requirements.componentsFromList.forEach(componentList => {
          result.push({
            name: this.getNamesFromList(componentList.list),
            type: this.LIST,
            quantity: this.getQuantity(componentList),
          })
        })
      }

      return result
    },

    getOneTimeOnSelects() {
      return this.getOnSelectData(this.option.onSelect.oneTime)
    },

    getEachTimeOnSelects() {
      return this.getOnSelectData(this.option.onSelect.eachTime)
    },

    getOnSelectData(onSelectData) {
      let result = []

      if (onSelectData.components) {
        onSelectData.components.forEach(component => {
          result.push({
            name: component.name,
            type: this.COMPONENT,
            weight: component.weight,
          })
        })
      }

      if (onSelectData.weightGroups) {
        onSelectData.weightGroups.forEach(component => {
          result.push({
            name: component.components,
            type: this.WEIGHT_GROUP,
            weight: component.weight,
          })
        })
      }

      return result
    },

    getQuantity(component) {
      if (component.quantity !== undefined) {
        return component.quantity
      }
      if (component.minQuantity && component.maxQuantity) {
        return component.minQuantity + '-' + component.maxQuantity
      } else if (component.minQuantity) {
        return 'minimum' + component.maxQuantity
      } else if (component.maxQuantity) {
        return 'maximum' + component.minQuantity
      }
    },

    getWeight() {
      if (!this.option.weight) {
        return 1
      }
      return this.option.weight
    },

    getNamesFromList(list) {
      let result = []
      list.forEach(component => {
        result.push(component.name)
      })

      return result
    },

    haveMultipleNames(data) {
      return Array.isArray(data.name)
    },
  },
}
