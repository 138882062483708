import LanguageSwitcher from '@/components/shared/language-switcher/LanguageSwitcher'
import { headerRxjsHelper } from '@/helpers/header-rxjs-helper'
import ImageLoader from '@/components/shared/image-loader/ImageLoader'
import Icon from '@/components/shared/icon/Icon.vue'
import { SESSION_KEY_BACK_URL } from '@/utils/session'

export default {
  name: 'TheHeader',

  components: { LanguageSwitcher, ImageLoader, Icon },

  props: {
    headerData: {
      type: Object,
      required: true,
    },
    email: {
      type: String,
      required: false,
    },
    phone: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      title: '',
      scrollThreshold: 100,
      isScrolled: false,
      backUrl: undefined,
      modalsData: {
        saveConfigurationModal: {},
        requestOptimizationModal: {},
      },
    }
  },

  created() {
    this.titleSubsciption = headerRxjsHelper.getTitle().subscribe(title => {
      this.title = title
    })

    this.modalDataSubscrition = headerRxjsHelper
      .getModalsData()
      .subscribe(modalsData => {
        this.modalsData = modalsData
        this.$forceUpdate()
      })

    window.addEventListener('scroll', this.handleScroll)

    this.backUrl = this.$session.get(SESSION_KEY_BACK_URL)
  },

  beforeDestroy() {
    this.titleSubsciption.unsubscribe()
    this.modalDataSubscrition.unsubscribe()
  },

  methods: {
    handleScroll() {
      if (!this.isScrolled) {
        this.isScrolled = window.scrollY > this.scrollThreshold
      }
      this.isScrolled = window.scrollY > this.scrollThreshold - 100
    },

    openSummaryModal(modal) {
      if (!modal) {
        return
      }

      headerRxjsHelper.setCurrentModal(modal)
    },

    redirectTo(href) {
      if (href === undefined) {
        return
      }

      window.location.href = href
    },

    goBack() {
      this.redirectTo(this.backUrl)
    },

    getShopUrl() {
      return this.$session.get('shopUrl')
    },

    isContactData() {
      return this.email !== undefined || this.phone !== undefined
    },
  },
}
