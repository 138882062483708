import axios from 'axios'
import i18n from '@/i18n'

//#region GET
const GET_BASE = 'base'
const GET_SPECIFICATION = 'Specifications'
const GET_SPECIFICATION_ORDER = 'order' // /Specifications/(...)/GET_SPECIFICATION_ORDER
//#endregion

//#region POST
const POST_SPECIFICATION = 'orders'
//#endregion

let http = null
class APIProvider {
  constructor(url, token) {
    this.create(url, token)
  }

  create(url, token) {
    http = axios.create({
      baseURL: url,
      headers: {
        'Content-Type': 'application/json',
        api_key: token,
      },
    })
  }

  //#region GET
  getBase() {
    return http
      .get(`/${GET_BASE}/${i18n.locale}`)
      .then(response => {
        return response
      })
      .catch(() => {
        return this._returnUnexpectedError()
      })
  }

  get(modelId, currency) {
    return http
      .get(
        `/${GET_SPECIFICATION}/${modelId}/${currency.toLowerCase()}/${
          i18n.locale
        }`
      )
      .then(response => {
        return response
      })
      .catch(() => {
        return this._returnUnexpectedError()
      })
  }

  getOrder(modelId, currency) {
    return http
      .get(
        `/${GET_SPECIFICATION}/${modelId}/${currency.toLowerCase()}/${
          i18n.locale
        }/${GET_SPECIFICATION_ORDER}`
      )
      .then(response => {
        return response
      })
      .catch(() => {
        return this._returnUnexpectedError()
      })
  }
  //#endregion

  //#region POST
  post(body) {
    return http
      .post('/' + POST_SPECIFICATION, body)
      .then(response => {
        return response
      })
      .catch(() => {
        return this._returnUnexpectedError()
      })
  }
  //#endregion

  _returnUnexpectedError() {
    return {
      status: 500,
      data: {
        message: 'shared.unexpected-error',
      },
    }
  }
}

export default new APIProvider(
  process.env.VUE_APP_API_URL,
  process.env.VUE_APP_API_TOKEN
)
