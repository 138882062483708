import FormMicroserviceAbstract from './form-microservice-abstract'

// Find solutions for:
export const INVALID_REQUIRE = 1
export const INVALID_WEIGHT = 2
export const INVALID_ATTRIBUTE = 3

export default class FormMicroserviceValidate extends FormMicroserviceAbstract {
  validate(modelInformationData, form) {
    let formObject = undefined
    let errors = {}

    modelInformationData.components.forEach(item => {
      formObject = form[item.name]
      errors[item.name] = []

      _checkIfRequire(item, formObject, errors)
      _checkIfUnique(item, formObject, errors)
      _checkIfIsRestrictedToOne(item, formObject, errors)
    })

    _checkIfComponentQuantityIsInRange(this, modelInformationData, errors)
    _checkIfComponentAttributesAreEqual(
      modelInformationData.attributes,
      form,
      errors
    )

    return errors
  }
}

function _checkIfRequire(item, formObject, errors) {
  if (
    item.isRequired &&
    (!formObject ||
      !formObject[0] ||
      !formObject[0].id ||
      formObject[0].quantity < 1)
  ) {
    errors[item.name].push({
      message: 'form.default.is-required',
      findSolutions: INVALID_REQUIRE,
    })
  }
}

function _checkIfUnique(item, formObject, errors) {
  if (item.isUnique && formObject) {
    formObject.every(object => {
      if (object.quantity > 1) {
        errors[item.name].push({
          message: 'form.default.is-not-unique',
        })
      }
    })
  }
}

function _checkIfIsRestrictedToOne(item, formObject, errors) {
  if (item.isRestrictedToOne && formObject && formObject[1]) {
    errors[item.name].push({
      message: 'form.default.you-can-only-select-one-item',
    })
  }
}

function _checkIfComponentQuantityIsInRange(
  microservice,
  modelInformationData,
  errors
) {
  modelInformationData.weightGroups.forEach(weightGroup => {
    if (
      true !== weightGroup.isUnlimited &&
      weightGroup.weightInUse > weightGroup.weight
    ) {
      const groupComponentsNames = _getComponentsNames(
        microservice,
        modelInformationData.components,
        weightGroup.options
      )
      _addWeightErrorToGroupComponents(
        groupComponentsNames,
        groupComponentsNames,
        errors
      )
    }
  })
}

function _getComponentsNames(microservice, components, weightGroupsOptions) {
  const componentsNames = []
  weightGroupsOptions.forEach(option => {
    const optionData = microservice.findOptionWithComponentName(
      components,
      option.id
    )
    if (undefined === optionData.componentName) {
      return
    }
    if (
      undefined ===
      componentsNames.find(name => name === optionData.componentName)
    ) {
      componentsNames.push(optionData.componentName)
    }
  })

  return componentsNames
}

function _addWeightErrorToGroupComponents(
  groupComponents,
  componentsNames,
  errors
) {
  _addErrorToGroupComponents(
    groupComponents,
    'form.default.too-many-selected-items',
    { components: componentsNames.join(', ') },
    errors,
    INVALID_WEIGHT
  )
}

function _addAttributeErrorsToGroupComponents(
  groupComponents,
  attributeName,
  optionsNames,
  errors
) {
  _addErrorToGroupComponents(
    groupComponents,
    'form.default.not-equal-attribute-value',
    {
      attribute: attributeName,
      options: optionsNames.join(', '),
    },
    errors,
    INVALID_ATTRIBUTE
  )
}

function _addErrorToGroupComponents(
  groupComponents,
  message,
  additional,
  errors,
  findSolutions
) {
  groupComponents.forEach(groupComponent => {
    errors[groupComponent].push({
      message: message,
      additional: additional,
      findSolutions: findSolutions,
    })
  })
}

function _checkIfComponentAttributesAreEqual(
  modelInformationAttributes,
  form,
  errors
) {
  const test = JSON.parse(JSON.stringify(modelInformationAttributes))
  const attributes = test.filter(attribute => attribute.requiredEqualValues)

  _initAttributeSelectedOptions(form, attributes)

  attributes.forEach(attribute => {
    if (
      0 === attribute.selectedOptions.length ||
      _isAttributeSelectedOptionsValuesEqual(
        attribute.options,
        attribute.selectedOptions
      )
    ) {
      return
    }

    _addAttributeErrors(
      attribute.options,
      attribute.selectedOptions,
      attribute.name,
      errors
    )
  })
}

function _initAttributeSelectedOptions(form, attributes) {
  _clearAttributeSelectedOptions(attributes)

  Object.keys(form).forEach(componentName => {
    form[componentName].forEach(selected => {
      const selectedAttributes = attributes.filter(
        attribute =>
          undefined !==
          attribute.options.find(option => option.id === selected.id)
      )
      if (0 < selectedAttributes.length) {
        _addToAttributeSelectedOptions(
          selected.id,
          selectedAttributes,
          attributes
        )
      }
    })
  })
}

function _clearAttributeSelectedOptions(attributes) {
  attributes.forEach(attribute => {
    attribute.selectedOptions = []
  })
}

function _addToAttributeSelectedOptions(
  selectedId,
  selectedAttributes,
  attributes
) {
  selectedAttributes.forEach(selectedAttribute => {
    const attribute = attributes.find(
      attribute => selectedAttribute.id === attribute.id
    )
    attribute.selectedOptions.push(selectedId)
  })
}

function _isAttributeSelectedOptionsValuesEqual(options, selectedOptions) {
  const start = _getFirstSelectedOptionIndexWithValue(options, selectedOptions)
  if (null === start) {
    return true
  }

  const first = options.find(option => option.id === selectedOptions[start])
  for (let i = start; i < selectedOptions.length; i++) {
    const next = options.find(option => option.id === selectedOptions[i])
    if (
      undefined !== next.value &&
      null !== next.value &&
      first.value !== next.value
    ) {
      return false
    }
  }

  return true
}

function _getFirstSelectedOptionIndexWithValue(options, selectedOptions) {
  for (let i = 0; i < selectedOptions.length; i++) {
    const option = options.find(option => option.id === selectedOptions[i])
    if (undefined !== option.value && null !== option.value) {
      return i
    }
  }

  return null
}

function _addAttributeErrors(options, selectedOptions, attributeName, errors) {
  const optionsNames = []
  const componentsNames = []

  for (let i = 0; i < selectedOptions.length; i++) {
    const option = options.find(option => option.id === selectedOptions[i])
    optionsNames.push(option.name)
    if (componentsNames.indexOf(option.componentName) === -1) {
      componentsNames.push(option.componentName)
    }
  }

  _addAttributeErrorsToGroupComponents(
    componentsNames,
    attributeName,
    optionsNames,
    errors
  )
}
