import ImageLoader from '@/components/shared/image-loader/ImageLoader'
import { Decimal } from 'decimal.js'

export default {
  name: 'FormSummaryModalComponent',

  components: {
    ImageLoader,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    quantity: {
      type: Number,
      required: true,
    },

    componentData: {
      type: Object,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },

    vat: {
      type: Number,
      required: false,
      default: 0.23,
      description: 'Default 0.23 (23%)',
    },
  },

  methods: {
    getPrice() {
      return (
        new Decimal(this.componentData.price)
          .plus(new Decimal(this.getTaxAmount()))
          .mul(this.quantity)
          .toFixed(2) +
        ' ' +
        this.currency
      )
    },

    getTaxAmount() {
      return new Decimal(this.componentData.price * this.vat).toFixed(2)
    },
  },
}
