import { Decimal } from 'decimal.js'

export default class FormMicroservicePrepDataForPost {
  prepDataForPost(
    modelId,
    modelInformationData,
    form,
    summaryFormFields,
    postType,
    quantity = 1
  ) {
    const dataForPost = {
      modelId: modelId,
      quantity: quantity,
      type: postType,
    }

    _setPrice(modelInformationData, form, dataForPost, quantity)
    _setOrderItems(form, dataForPost)
    _setSummaryFormFieldsData(summaryFormFields, dataForPost)

    return dataForPost
  }
}

function _setPrice(modelInformationData, form, dataForPost, quantity) {
  let price = new Decimal(modelInformationData.price)
  Object.keys(form).forEach(componentName => {
    const modelComponent = modelInformationData.components.find(
      component => component.name === componentName
    )
    if (
      undefined === modelComponent ||
      undefined === modelComponent.list ||
      !Array.isArray(modelComponent.list)
    ) {
      return
    }

    price = price.plus(
      _getAggregatedPriceForComponent(modelComponent.list, form[componentName])
    )
  })

  dataForPost.price = new Decimal(price * quantity).toFixed(2)
  dataForPost.priceFrontend = new Decimal(price * quantity).toFixed(2)
}

function _getAggregatedPriceForComponent(options, selectedOptions) {
  let price = new Decimal(0)
  selectedOptions.forEach(selectedOption => {
    const option = options.find(x => x.id === selectedOption.id)
    if (undefined === option) {
      return
    }

    price = price.plus(new Decimal(option.price * selectedOption.quantity))
  })

  return price
}

function _setOrderItems(form, dataForPost) {
  const orderItems = []

  Object.keys(form).forEach(componentName => {
    form[componentName].forEach(selectedOption => {
      orderItems.push({
        modelComponentId: selectedOption.id,
        quantity: selectedOption.quantity,
      })
    })
  })

  dataForPost.orderItems = orderItems
}

function _setSummaryFormFieldsData(summaryFormFields, dataForPost) {
  Object.keys(summaryFormFields).forEach(field => {
    dataForPost[field] = summaryFormFields[field]
  })
}
