import { Decimal } from 'decimal.js'
import FormMicroserviceValidate from './microservices/form-microservice-validate'
import FormMicroserviceOnSelect from './microservices/form-microservice-on-select'
import FormMicroservicePrepDataForPost from './microservices/form-microservice-prep-data-for-post'
import FormMicroserviceRequirements from './microservices/form-microservice-requirements'
import FormMicroserviceGetChanges from './microservices/form-microservice-get-changes'
import FormMicroserviceManageWeights from './microservices/form-microservice-manage-weights'
import FormMicroservicePrepDataOnStart from './microservices/form-microservice-prep-data-on-start'

export default class FormService {
  microserviceValidation = new FormMicroserviceValidate()
  microserviceOnSelect = new FormMicroserviceOnSelect()
  microservicePrepDataForPost = new FormMicroservicePrepDataForPost()
  microserviceRequirements = new FormMicroserviceRequirements()
  microserviceGetChanges = new FormMicroserviceGetChanges()
  microserviceManageWeights = new FormMicroserviceManageWeights()
  microservicePrepDataOnStart = new FormMicroservicePrepDataOnStart()

  getFormChanges(oldForm, lastListInput) {
    return this.microserviceGetChanges.getFormChanges(oldForm, lastListInput)
  }

  onSelect(modelInformationData, oldForm, changes) {
    this.microserviceOnSelect.onSelect(modelInformationData, oldForm, changes)
  }

  getRequirementsPriorityByChanges(components, form, changes) {
    return this.microserviceRequirements.getRequirementsPriorityByChanges(
      components,
      form,
      changes
    )
  }

  addApprovedRequirementsToForm(components, form, approvedChanges) {
    this.microserviceRequirements.addApprovedRequirementsToForm(
      components,
      form,
      approvedChanges
    )
  }

  validate(modelInformationData, form) {
    return this.microserviceValidation.validate(modelInformationData, form)
  }

  prepDataForPost(
    modelId,
    modelInformationData,
    form,
    summaryFormFields,
    postType
  ) {
    return this.microservicePrepDataForPost.prepDataForPost(
      modelId,
      modelInformationData,
      form,
      summaryFormFields,
      postType
    )
  }

  getDefaultFormSelectionObject(components) {
    const changes = []
    const form = {}

    components.forEach(component => {
      component.list.forEach(option => {
        if (option.selectedQuantity) {
          if (!form[component.name]) {
            form[component.name] = []
          }

          form[component.name].push({
            id: option.id,
            quantity: option.selectedQuantity,
          })
          changes.push({
            id: option.id,
            quantity: option.selectedQuantity,
          })
        }
      })
    })

    return { form: form, changes: changes }
  }

  invalidFeedbacksExists(invalidFeedbacks) {
    let exists = false
    Object.keys(invalidFeedbacks).some(componentName => {
      if (
        invalidFeedbacks[componentName] &&
        0 < invalidFeedbacks[componentName].length
      ) {
        exists = true

        return true
      }
    })

    return exists
  }

  createWeightGroupsIndexes(modelInformationDataWeightGroups) {
    this.microserviceManageWeights.createWeightGroupsIndex(
      modelInformationDataWeightGroups
    )
  }

  findAndChooseBestSelectedOptionDistributionBaseOnWholeForm(
    modelInformationData,
    form
  ) {
    const bestPossibility = this.microserviceManageWeights.getBestSelectedOptionDistributionBaseOnWholeForm(
      modelInformationData.components,
      modelInformationData.weightGroups,
      form
    )
    modelInformationData.weightGroups = bestPossibility.possibility
  }

  findAndChooseBestSelectedOptionDistributionBaseOnChange(
    modelInformationData,
    form,
    changes
  ) {
    const bestPossibility = this.microserviceManageWeights.getBestSelectedOptionDistributionBaseOnChangeAndForm(
      modelInformationData.components,
      modelInformationData.weightGroups,
      form,
      changes
    )
    modelInformationData.weightGroups = bestPossibility.possibility
  }

  prepDataOnStart(modelInformationData) {
    this.microservicePrepDataOnStart.prepDataOnStart(modelInformationData)
  }

  runFindingSolutions(
    modelInformationData,
    form,
    invalidFeedbacks,
    workers,
    refreshComponent
  ) {
    Object.keys(invalidFeedbacks).forEach(componentName => {
      invalidFeedbacks[componentName].forEach(invalidFeedback => {
        if (
          invalidFeedback.findSolutions !== undefined &&
          invalidFeedback.findSolutions !== null
        ) {
          const worker = new Worker('./workers/form-worker-find-solutions.js', {
            type: 'module',
          })
          worker.onmessage = event => {
            invalidFeedback.solutions = event.data
            refreshComponent()
          }
          worker.postMessage([
            modelInformationData,
            form,
            invalidFeedbacks,
            invalidFeedback,
            componentName,
          ])
          workers.push(worker)
        }
      })
    })
  }

  closeWorkers(workers) {
    workers.forEach(worker => {
      worker.postMessage(['close'])
    })
    workers.splice(0, workers.length)
  }

  computeTotalPrice(formCurrent, components, modelPrice, vat) {
    let totalPrice = {
      gross: 0,
      net: 0,
    }
    _computeTotalNetPrice(totalPrice, formCurrent, components, modelPrice)
    _computeTotalGrossPrice(totalPrice, vat)

    return totalPrice
  }
}

function _computeTotalNetPrice(
  totalPrice,
  formCurrent,
  components,
  modelPrice
) {
  Object.entries(formCurrent).forEach(element => {
    const component = components.find(x => x.name === element[0])

    if (Array.isArray(element[1])) {
      element[1].forEach(item => {
        const object = component.list.find(x => x.id === item.id)

        if (object) {
          const price = new Decimal(object.price * item.quantity)

          if (!price.equals(0)) {
            totalPrice.net = price.plus(new Decimal(totalPrice.net))
          }
        }
      })
    }
  })

  totalPrice.net = new Decimal(totalPrice.net).plus(modelPrice)
}

function _computeTotalGrossPrice(totalPrice, vat) {
  totalPrice.gross = new Decimal(totalPrice.net).plus(
    new Decimal(_getTaxAmount(totalPrice, vat))
  )
}

function _getTaxAmount(totalPrice, vat) {
  return new Decimal(totalPrice.net * vat).toFixed(2)
}
