export default class FormMicroservicePrepDataOnStart {
  prepDataOnStart(modelInformationData) {
    _prepAttributes(modelInformationData)
  }
}

function _prepAttributes(modelInformationData) {
  _cleareAttributesOptions(modelInformationData.attributes)

  modelInformationData.components.forEach(component => {
    const optionsWithAttributes = component.list.filter(
      option => undefined !== option.attributes && 0 < option.attributes.length
    )
    _addOptionsToAttributesAndAttributeNameToOptions(
      component.name,
      optionsWithAttributes,
      modelInformationData.attributes
    )
  })
}

function _cleareAttributesOptions(attributes) {
  attributes.forEach(attribute => {
    attribute.options = []
  })
}

function _addOptionsToAttributesAndAttributeNameToOptions(
  componentName,
  options,
  attributes
) {
  options.forEach(option => {
    option.attributes.forEach(optionAttribute => {
      const attributeData = attributes.find(
        attribute => attribute.id === optionAttribute.id
      )
      _addOptionToAttribute(
        option,
        optionAttribute,
        componentName,
        attributeData
      )
      _addAttributeNamePropertyToOption(optionAttribute, attributeData.name)
    })
  })
}

function _addOptionToAttribute(
  option,
  optionAttribute,
  componentName,
  attributeData
) {
  attributeData.options.push({
    id: option.id,
    name: option.name,
    componentName: componentName,
    value: optionAttribute.value,
  })
}

function _addAttributeNamePropertyToOption(optionAttribute, attributeName) {
  optionAttribute['name'] = attributeName
}
