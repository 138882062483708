export default {
  name: 'FormSummaryModalFormModalForm',

  props: {
    fields: {
      type: Object,
    },

    values: {
      type: Object,
      required: true,
    },

    isConfirmed: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      errors: {},
    }
  },

  computed: {
    valuesModel() {
      return Object.assign({}, this.values)
    },
  },

  watch: {
    valuesModel: {
      deep: true,
      handler(values, valuesOld) {
        this.validate(this.getValuesDiff(values, valuesOld))
      },
    },

    isConfirmed: {
      handler(value) {
        if (value) {
          this.$emit('submitForm', this.validate())
        }
      },
    },

    fields: {
      handler() {
        this.setValues()
      },
    },
  },

  methods: {
    setValues() {
      if (this.fields === undefined) {
        return
      }

      Object.keys(this.fields).forEach(fieldName => {
        const field = this.fields[fieldName]
        if (field.value !== undefined) {
          this.values[field.name] = field.value
        }
      })
    },

    isFieldEnabled(field) {
      if (field.conditionalId === undefined) {
        return true
      }

      const targetFieldValue = this.valuesModel[field.conditionalId]
      field.skipVaidation = !targetFieldValue
      return targetFieldValue
    },

    checkIfFieldsExists() {
      return this.fields !== undefined && Object.keys(this.fields).length > 0
    },

    isHtmlLabel(field) {
      return (
        field !== undefined &&
        field.label !== undefined &&
        field.labelType === 'html'
      )
    },

    validate(values) {
      if (this.fields === undefined || Object.keys(this.fields).length === 0) {
        return true
      }

      let fieldsToValidate = {}

      if (values === undefined) {
        this.errors = {}
        fieldsToValidate = this.fields
      } else {
        this.prepareFieldsToValidate(values, fieldsToValidate)
      }

      Object.keys(fieldsToValidate).forEach(fieldName => {
        const field = this.fields[fieldName]

        if (field.skipVaidation) {
          return
        }

        if (values !== undefined) {
          if (values[fieldName] === undefined) {
            return
          } else {
            this.errors[fieldName] = undefined
          }
        }

        const value = this.values[field.name]

        this.checkFieldRequiredUpdateErrors(field, value)

        if (field.required === true || (value !== undefined && value !== '')) {
          this.checkFieldRegExAndUpdateErrors(field, value)
        }
      })

      return this.isFormErrors()
    },

    prepareFieldsToValidate(values, fieldsToValidate) {
      Object.keys(this.fields).forEach(fieldName => {
        if (
          values[fieldName] !== undefined &&
          this.fields[fieldName].name === fieldName
        ) {
          fieldsToValidate[fieldName] = this.fields[fieldName]
        }
      })
    },

    isFormErrors() {
      for (const key in Object.keys(this.errors)) {
        if (this.errors[key] === undefined) {
          return false
        }
      }

      return true
    },

    checkFieldRequiredUpdateErrors(field, value) {
      if (!field.required) {
        return
      }

      if (value === undefined || value === '') {
        this.errors[field.name] =
          'form.summary-modal.form-modal.form.field-required'
      }

      if (field.type === 'checkbox' && !value) {
        this.errors[field.name] =
          'form.summary-modal.form-modal.form.checkbox-required'
      }
    },

    checkFieldRegExAndUpdateErrors(field, value) {
      if (
        field.validationRegEx !== undefined &&
        !field.validationRegEx.test(String(value))
      ) {
        if (field.regExMessage !== undefined) {
          this.errors[field.name] = field.regExMessage
        } else {
          this.errors[field.name] =
            'form.summary-modal.form-modal.form.invalid-regex'
        }
      }
    },

    hasProperFieldType(field, type) {
      return field !== undefined && field.type === type
    },

    getLabelParams(field) {
      if (field !== undefined && field.labelParams !== undefined) {
        return field.labelParams
      }
      return {}
    },

    getValuesDiff(values, oldValues) {
      let differences = {}
      Object.keys(values).forEach(fieldName => {
        if (oldValues[fieldName] !== values[fieldName]) {
          differences[fieldName] = values[fieldName]
        }
      })

      return differences
    },
  },
}
