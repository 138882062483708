export default {
  name: 'ImageLoader',

  props: {
    src: {
      type: String || null,
      default: '',
    },
  },

  computed: {
    imageSource() {
      if (this.src == undefined) {
        return ''
      }

      return this.src
    },
  },

  methods: {
    handleAbsolutePathImageError(event) {
      try {
        this.loadImagePathFromAssets(event)
      } catch {
        this.setImagePlaceholder(event)
      }
    },

    loadImagePathFromAssets(event) {
      event.target.src = require(`@/assets/${this.src}`)
    },

    setImagePlaceholder(event) {
      try {
        event.target.src = require('@/assets/no-image.png')
      } catch {
        event.target.src = ''
      }
    },
  },
}
