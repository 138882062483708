import FormOptionDetailsBox from '@/components/form/default/form-option-details-box/FormOptionDetailsBox'
import FormOptionDetailsBoxInfo from '@/components/form/default/form-option-details-box/form-option-details-box-info/FormOptionDetailsBoxInfo'
import FormOptionDetailsBoxService from '../form-option-details-box/form-option-details-box-service'
import FormListFilterService from '../form-list-filter-bar/form-list-filter-service'
import FormListFilterBar from '@/components/form/default/form-list-filter-bar/FormListFilterBar'
import FormListCollapsedBar from '@/components/form/default/form-list-collapsed-bar/FormListCollapsedBar'
import FormListHelper from '@/helpers/form-list-helper'

export default {
  name: 'FormChooseCountableMultipleOptionList',

  components: {
    FormOptionDetailsBox,
    FormOptionDetailsBoxInfo,
    FormListFilterBar,
    FormListCollapsedBar,
  },

  props: {
    value: {
      type: [] | null,
      required: true,
    },

    attributes: {
      type: [] | null,
    },

    listName: {
      type: String,
      default: '',
    },

    options: {
      type: Array,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },

    minQuantity: {
      type: Number,
      default: 0,
    },

    maxQuantity: {
      type: Number,
      default: 99,
    },

    isError: {
      type: Boolean,
      default: false,
    },

    isSubmittable: {
      type: Boolean,
      default: false,
    },

    image: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      selected: [],
      currentFilters: [],
      lastSelectedEmit: [],
      hiddenOptions: [],
      optionsData: this.copyObject(this.options),
      optionDetailsService: new FormOptionDetailsBoxService(this.options),
      formFilterBarService: new FormListFilterService(),
      formListHelper: new FormListHelper(),
      isSubmitted: false,
    }
  },

  async created() {
    this.setSelectionData(this.recreateDataFrom(this.value))
    this.formFilterBarService.setOptionsVisible(this.optionsData)
  },

  computed: {
    isOptionSelected() {
      return option => {
        return this.formListHelper.isOptionSelected(option, this.value)
      }
    },
  },

  watch: {
    value: {
      deep: true,
      handler(val) {
        this.setSelectionData(this.recreateDataFrom(val))
      },
    },

    selected: {
      deep: true,
      handler(val) {
        if (JSON.stringify(val) === JSON.stringify(this.oldSelectedEmit)) {
          return
        }

        this.emit(val)
        this.oldSelectedEmit = this.copyObject(this.selected)
      },
    },
  },

  methods: {
    recreateDataFrom(val) {
      const data = []

      this.options.forEach(option => {
        let item = null
        if (undefined !== val) {
          item = val.find(item => item.id === option.id)
        }
        data[option.id] = item ? item.quantity : 0
      })

      return data
    },

    setSelectionData(data) {
      this.selected = data
      this.oldSelectedEmit = this.copyObject(data)
    },

    emit(value) {
      let emit = []
      value.forEach((quantity, index) => {
        if (this.minQuantity < quantity) {
          emit.push({
            id: index,
            quantity: quantity,
          })
        }
      })

      this.$emit('input', emit)
    },

    clearSelectedOption(optionId) {
      let emit = []

      this.selected.forEach((quantity, index) => {
        if (index !== optionId && this.minQuantity < quantity) {
          emit.push({
            id: index,
            quantity: quantity,
          })
        }
      })

      this.$emit('input', emit)
      this.formListHelper.updateHiddenOptions(emit)
    },

    filterOptions(event) {
      this.formFilterBarService.setOptionsVisibilty(this.optionsData, event)
      this.formListHelper.updateHiddenOptions(this.value)
      this.$forceUpdate()
    },

    toggleSubmitted() {
      this.isSubmitted = !this.isSubmitted

      if (!this.isSubmitted) {
        this.formListHelper.reloadHiddenOptions(
          this.hiddenOptions,
          this.optionsData,
          this.value
        )
      }

      this.$emit('submitted', {
        name: this.listName,
        isSubmitted: this.isSubmitted,
      })
      this.formListHelper.scrollToElementById(this.listName)
    },
  },
}
