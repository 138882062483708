import FormService from './../../../../../views/form/form-service'
import Vue from 'vue'
import LoadingOverlayGear from '@/components/shared/loading-overlay/loading-overlay-gear/LoadingOverlayGear.vue'
import ImageLoader from '@/components/shared/image-loader/ImageLoader'
import FormSummaryModalFormModalForm from '@/components/form/default/form-summary-modal/form-summary-modal-form-modal/form-summary-modal-form-modal-form/FormSummaryModalFormModalForm'

export const STATUS_ERROR = 'error'
export const STATUS_SUCCESS = 'success'
export const STATUS_LOADING = 'loading'

export const VIEW_FORM = 'form'
export const VIEW_LOADING = 'loading'
export const VIEW_COMPLETE = 'complete'

export default {
  name: 'FormSummaryModalFormModal',

  components: {
    FormSummaryModalFormModalForm,
    LoadingOverlayGear,
    ImageLoader,
  },

  props: {
    modalData: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    modelInformationData: {
      type: Object,
      required: true,
    },
    modalFormValues: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formService: new FormService(),
      isFormConfirmed: false,
      isConfirmDisabled: false,
      modalView: undefined,
      responseData: {},
      STATUS_SUCCESS: STATUS_SUCCESS,
      STATUS_ERROR: STATUS_ERROR,
      STATUS_LOADING: STATUS_LOADING,
      VIEW_FORM: VIEW_FORM,
      VIEW_LOADING: VIEW_LOADING,
      VIEW_COMPLETE: VIEW_COMPLETE,
    }
  },

  created() {
    if (this.modalData.modalStatus == undefined) {
      this.modalView = VIEW_FORM
    }
  },

  watch: {
    modalFormValues: {
      deep: true,
      handler(value) {
        this.$emit('modalFormValues', value)
      },
    },

    modalData: {
      deep: true,
      handler() {
        if (this.modalView !== VIEW_LOADING) {
          this.setViewState()
        }
      },
    },
  },

  methods: {
    setViewState() {
      switch (this.modalData.modalStatus) {
        case undefined:
          this.modalView = VIEW_FORM
          break
        case STATUS_LOADING:
          this.modalView = VIEW_LOADING
          break
        default:
          this.modalView = VIEW_COMPLETE
      }
    },

    getModalData(key) {
      if (this.modalData != undefined && this.modalData[key] != undefined) {
        return this.modalData[key]
      }
    },

    getContactDataWithoutIgnoredFields() {
      let contactData = {}

      Object.keys(this.modalFormValues).forEach(field => {
        if (
          this.modalData.modalFields != undefined &&
          this.modalData.modalFields[field] != undefined &&
          !this.modalData.modalFields[field].isIgnoredInRequest
        ) {
          contactData[field] = this.modalFormValues[field]
        }
      })
      return contactData
    },

    submitForm(isValidated) {
      this.isConfirmDisabled = false
      this.isFormConfirmed = false

      if (isValidated) {
        this.sendRequest()
      }
    },

    async sendRequest() {
      const body = this.formService.prepDataForPost(
        this.modelInformationData.id,
        this.modelInformationData,
        this.form,
        this.getContactDataWithoutIgnoredFields(),
        this.modalData.postType
      )

      this.$set(this.modalData, 'modalStatus', STATUS_LOADING)
      this.modalView = VIEW_LOADING

      const response = await Vue.$api.post(body)
      this.modalView = VIEW_COMPLETE

      if (200 !== response.status) {
        if (response.data) {
          this.responseData.message = response.data.msg
        }
        this.$set(this.modalData, 'modalStatus', STATUS_ERROR)

        return
      }

      if (response.data) {
        if (response.data.url != undefined) {
          this.responseData.url = response.data.url
        }

        this.responseData.message = response.data.msg
      }

      this.$set(this.modalData, 'modalStatus', STATUS_SUCCESS)
    },

    confirmForm() {
      this.isConfirmDisabled = true
      this.isFormConfirmed = true
    },

    switchToFormView() {
      this.modalView = VIEW_FORM
    },

    getRequestButtonMessage() {
      if (
        this.modalData != undefined &&
        this.modalData.requestButton != undefined
      ) {
        return this.modalData.requestButton
      }

      return 'form.summary-modal.form-modal.send-request'
    },

    closeModal() {
      this.$bvModal.hide('form-summary-modal-form-modal')
    },

    copyUrl() {
      if (this.responseData.url != undefined) {
        navigator.clipboard.writeText(this.responseData.url)
      }
    },
  },
}
