import FormChooseCountableMultipleOptionList from '@/components/form/default/form-choose-countable-multiple-option-list/FormChooseCountableMultipleOptionList'
import FormOptionRequirementsModalSingle from '@/components/form/default/form-option-requirements-modal/form-option-requirements-modal-single/FormOptionRequirementsModalSingle'
import FormOptionRequirementsModalService from './form-option-requirements-modal-service'
import FormOptionRequirementsModalList from '@/components/form/default/form-option-requirements-modal/form-option-requirements-modal-list/FormOptionRequirementsModalList'

export default {
  name: 'FormOptionRequirementsModal',

  components: {
    FormChooseCountableMultipleOptionList,
    FormOptionRequirementsModalList,
    FormOptionRequirementsModalSingle,
  },

  props: {
    formData: { type: Object, required: true },

    formRequirements: {
      formRequirements: Object,
      required: true,
    },

    modelInformationData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      service: new FormOptionRequirementsModalService(
        this.modelInformationData
      ),
      SINGLE: 'single',
      LIST: 'list',
      form: this.copyObject(this.formData),
      formOld: this.copyObject(this.formData),
      formChanges: [],
      unvalidatedLists: [],
      confirmCheckbox: false,
      validationErrorMsg: '',
    }
  },

  watch: {
    form: {
      deep: true,
      handler(form) {
        this.saveFormChanges(form)
      },
    },
  },

  methods: {
    saveFormChanges(form) {
      Object.keys(form).forEach(componentKey => {
        let changes = this.service.getChanges(
          form[componentKey],
          this.formOld[componentKey]
        )
        this.service.commitChanges(changes, this.formChanges)
      })
      this.formOld = this.copyObject(form)
    },

    printTotalRequirementsPrice() {
      let totalPrice = 0
      this.formChanges.forEach(change => {
        totalPrice +=
          Math.abs(change.quantity) *
          this.service.getPrice(
            change.id,
            change.quantity,
            change.quantity > 0,
            true
          )
      })
      return totalPrice + ' ' + this.modelInformationData.currency
    },

    onListValidate(validate) {
      if (validate.isValidated === false) {
        this.unvalidatedLists[validate.id] = validate
      } else {
        delete this.unvalidatedLists[validate.id]
      }
    },

    getRequirements(list, type) {
      let requirements = []

      if (list === undefined) {
        return requirements
      }

      list.forEach((requirement, key) => {
        if (type === this.SINGLE && requirement.list === undefined) {
          requirements[requirement.id] = requirement
        } else if (type === this.LIST && requirement.list !== undefined) {
          requirements[key] = requirement
        }
      })

      return requirements
    },

    submitModal() {
      if (Object.keys(this.unvalidatedLists).length === 0) {
        this.$emit('formChanges', this.formChanges)
        this.closeModal()
      } else {
        this.validationErrorMsg = this.$t(
          'form.requirements-modal.validation-error'
        )
      }
    },

    closeModal() {
      this.$emit('formChanges', false)
      this.$bvModal.hide('requirements-modal')
    },

    setDefaultModalData() {
      this.form = this.copyObject(this.formData)
      this.formOld = this.copyObject(this.formData)
      this.formChanges = []
      this.confirmCheckbox = false
      this.validationErrorMsg = ''
    },
  },
}
