import FormOptionRequirementsModalService from './../form-option-requirements-modal-service'
import FormMicroserviceRequirements from '@/views/form/microservices/form-microservice-requirements.js'
import FormChooseCountableMultipleOptionList from '@/components/form/default/form-choose-countable-multiple-option-list/FormChooseCountableMultipleOptionList'

export default {
  name: 'FormOptionRequirementsModalList',

  components: {
    FormChooseCountableMultipleOptionList,
  },

  props: {
    requirement: {
      type: Object,
      required: true,
    },

    modelInformationData: {
      type: Object,
      required: true,
    },

    formData: {
      type: Object,
      required: true,
    },

    id: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      service: new FormOptionRequirementsModalService(
        this.modelInformationData
      ),
      requirementsService: new FormMicroserviceRequirements(),
      invalidFeedbacks: [],
      listRequirements: [],
      oldInputs: [],
      listData: [],
    }
  },

  async created() {
    this.validate([])
  },

  methods: {
    validateAndSaveValue(listInputs) {
      this.validate(listInputs)
      const changes = this.service.getChanges(listInputs, this.oldInputs)

      this.saveChangesToForm(changes)
      this.oldInputs = listInputs
    },

    saveChangesToForm(changes) {
      this.requirementsService.addApprovedRequirementsToForm(
        this.modelInformationData.components,
        this.formData,
        changes
      )
    },

    validate(inputs) {
      const quantityLimit = this.requirement.quantity
      let quantity = 0

      this.invalidFeedbacks = []

      inputs.forEach(input => {
        quantity += input.quantity
      })

      if (Math.abs(quantityLimit) !== Math.abs(quantity)) {
        this.invalidFeedbacks.push({
          message: 'form.requirements-modal.wrong-quantity',
          additional: { count: quantityLimit },
        })
      }

      this.$emit('validate', {
        id: this.id,
        isValidated: this.isValidated(),
      })
    },

    isValidated() {
      return this.invalidFeedbacks.length === 0
    },

    getOptionsForList(requirement) {
      let resultOptions = []

      requirement.list.forEach(option => {
        resultOptions.push({
          id: option.id,
          name: option.name,
          price: this.service.getPrice(
            option.id,
            1,
            this.service.isQuantityPositive(requirement),
            true
          ),
          requirements: this.getOptionRequirements(option.id),
        })
      })

      return resultOptions
    },

    getOptionRequirements(id) {
      let components = this.modelInformationData.components
      for (let i = 0; i < Object.keys(components).length; i++) {
        let list = this.modelInformationData.components[i].list
        for (let j = 0; j < list.length; j++) {
          if (list[j].id == id && list[j].requirements !== undefined) {
            return list[j].requirements
          }
        }
      }
    },

    getMinQuantity(requirement) {
      return this.service.isQuantityPositive(requirement) ? 0 : -999
    },

    getMaxQuantity(requirement) {
      return this.service.isQuantityPositive(requirement) ? 999 : 0
    },
  },
}
