import { render, staticRenderFns } from "./FormOptionDetailsCollapseAttributes.vue?vue&type=template&id=765957bb&scoped=true&"
import script from "./form-option-details-collapse-attributes.js?vue&type=script&lang=js&"
export * from "./form-option-details-collapse-attributes.js?vue&type=script&lang=js&"
import style0 from "./form-option-details-collapse-attributes.scss?vue&type=style&index=0&id=765957bb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "765957bb",
  null
  
)

export default component.exports