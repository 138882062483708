export default class FormOptionRequirementsModalService {
  modelInformationData = null

  copyObject(object) {
    return JSON.parse(JSON.stringify(object))
  }

  constructor(modelInformationData) {
    this.modelInformationData = modelInformationData
  }

  commitChanges(changes, target) {
    changes.forEach(change => {
      let found = _searchForExistingObjectInTarget(change, target)
      _commitChangesToTarget(change, target, found)
    })
  }

  getChanges(incoming, old) {
    let changes = []
    let olds = this.copyObject(old)
    let incomings = this.copyObject(incoming)

    _computeChangesForExistingObjects(changes, incomings, olds)
    _pushNewObjects(changes, incomings)
    _pushNotExistingObjects(changes, olds)
    _removeChangesWithQuantityZero(changes)

    return changes
  }

  isQuantityPositive(requirement) {
    if (requirement && requirement.quantity) {
      return requirement.quantity >= 0
    }
    return true
  }

  getPrice(id, quantity, isPositive, isReturnOnlyNumber) {
    let components = this.modelInformationData.components
    if (components === undefined) {
      return
    }

    for (let i = 0; i < components.length; i++) {
      let list = components[i].list
      if (list === undefined) {
        return
      }

      for (let j = 0; j < list.length; j++) {
        if (list[j].id === id) {
          return _getFormattedPrice(
            list[j],
            quantity,
            isPositive,
            isReturnOnlyNumber,
            this.modelInformationData
          )
        }
      }
    }
  }
}

function _getFormattedPrice(
  item,
  quantity,
  isPositive,
  isReturnOnlyNumber,
  modelInformationData
) {
  const price = isPositive ? item.price : -Math.abs(item.price)
  const char = price > 0 ? '+' : ''

  return isReturnOnlyNumber
    ? price
    : char + price * Math.abs(quantity) + ' ' + modelInformationData.currency
}

function _computeChangesForExistingObjects(changes, incomings, olds) {
  incomings.forEach((incoming, incomingKey) => {
    olds.forEach((old, oldkey) => {
      if (old !== null && incoming !== null && incoming.id === old.id) {
        changes.push({
          id: incoming.id,
          quantity: incoming.quantity - old.quantity,
          name: incoming.name,
        })
        delete incomings[incomingKey]
        delete olds[oldkey]
      }
    })
  })
}

function _pushNewObjects(changes, incomings) {
  incomings.forEach(incoming => {
    changes.push({
      id: incoming.id,
      quantity: incoming.quantity,
      name: incoming.name,
    })
  })
}

function _pushNotExistingObjects(changes, olds) {
  olds.forEach(old => {
    changes.push({
      id: old.id,
      quantity: -old.quantity,
      name: old.name,
    })
  })
}

function _removeChangesWithQuantityZero(changes) {
  changes.forEach((change, key) => {
    if (change.quantity === 0) {
      delete changes[key]
    }
  })
}

function _searchForExistingObjectInTarget(change, target) {
  return target.find(t => change.id != undefined && t.id === change.id)
}

function _commitChangesToTarget(change, target, found) {
  if (found === undefined) {
    target.push(change)
  } else {
    found.quantity += change.quantity
  }
}
