import ImageLoader from '@/components/shared/image-loader/ImageLoader'

export default {
  name: 'ServerTotalPriceAndInfo',

  components: {
    ImageLoader,
  },

  props: {
    totalPriceNet: {
      type: String,
      required: true,
    },

    totalPriceGross: {
      type: String,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },

    modelName: {
      type: String | undefined,
      required: false,
    },

    seriesName: {
      type: String | undefined,
      required: false,
    },

    producerLogo: {
      type: String | undefined,
      required: false,
    },

    modelImage: {
      type: String | undefined,
      required: false,
    },
  },
}
