import { headerRxjsHelper } from '@/helpers/header-rxjs-helper'
import TheHeader from '@/components/the-header/TheHeader'
import TheFooter from '@/components/the-footer/TheFooter'
import Vue from 'vue'

export default {
  name: 'App',

  components: {
    TheHeader,
    TheFooter,
  },

  data() {
    return {
      appData: null,
    }
  },

  async created() {
    const response = await Vue.$api.getBase()
    if (200 !== response.status) {
      return
    }
    this.appData = response.data

    headerRxjsHelper.setContactData({
      phone: this.appData.phone,
      email: this.appData.email,
    })
  },
}
