import FormMicroserviceAbstract from './form-microservice-abstract'

export default class FormMicoserviceOnSelect extends FormMicroserviceAbstract {
  onSelect(modelInformationData, oldForm, changes) {
    if (!changes || !Array.isArray(changes)) {
      return
    }

    changes.forEach(item => {
      const optionWithComponentName = this.findOptionWithComponentName(
        modelInformationData.components,
        item.id
      )

      const option = optionWithComponentName.option
      if (!option || !option.onSelect) {
        return
      }

      if (
        _makeChangesForOneTimeSelection(
          oldForm,
          optionWithComponentName,
          item.quantity
        )
      ) {
        // selected modifier = +
        // unselected modifier = -
        const modifier = 0 < item.quantity ? 1 : -1

        _changeGroupsWeigths(
          modelInformationData.weightGroups,
          option.onSelect.oneTime.weightGroups,
          modifier
        )
        _changeComponentsWeigths(
          modelInformationData.components,
          option.onSelect.oneTime.components,
          modifier
        )
        _changeAttributes(
          modelInformationData.attributes,
          option.onSelect.oneTime.attributes,
          modifier
        )
      }

      if (option.onSelect.eachTime) {
        _changeGroupsWeigths(
          modelInformationData.weightGroups,
          option.onSelect.eachTime.weightGroups,
          item.quantity
        )
        _changeComponentsWeigths(
          modelInformationData.components,
          option.onSelect.eachTime.components,
          item.quantity
        )
        _changeAttributes(
          modelInformationData.attributes,
          option.onSelect.eachTime.attributes,
          item.quantity
        )
      }
    })
  }
}

function _makeChangesForOneTimeSelection(
  oldForm,
  optionWithComponentName,
  itemQuantity
) {
  if (!optionWithComponentName.option.onSelect.oneTime) {
    return false
  }

  const selectedObject = _isOneTimeSelectionDone(
    oldForm,
    optionWithComponentName
  )

  if (0 < itemQuantity) {
    return selectedObject === undefined
  }

  return selectedObject.quantity + itemQuantity === 0
}

function _isOneTimeSelectionDone(oldForm, optionWithComponentName) {
  if (oldForm[optionWithComponentName.componentName]) {
    return oldForm[optionWithComponentName.componentName].find(
      option => option.id === optionWithComponentName.option.id
    )
  }

  return undefined
}

function _changeGroupsWeigths(weightGroups, items, multiple = 1) {
  if (!weightGroups || !Array.isArray(items)) {
    return
  }

  let change = undefined
  weightGroups.forEach(weightGroup => {
    change = items.find(item => item.id === weightGroup.id)
    if (!change) {
      return
    }

    if (change.weight) {
      weightGroup.weight += change.weight * multiple
    }
    if (change.isUnlimited !== undefined && change.isUnlimited !== null) {
      weightGroup.isUnlimited =
        0 < multiple ? change.isUnlimited : !change.isUnlimited
    }
  })
}

function _changeComponentsWeigths(components, items, multiple = 1) {
  if (!components || !Array.isArray(items)) {
    return
  }

  let change = undefined
  components.forEach(component => {
    component.list.forEach(option => {
      change = items.find(item => item.id === option.id)
      if (!change) {
        return
      }

      option.weight = option.weight ? option.weight : 1
      option.weight += change.weight * multiple
    })
  })
}

function _changeAttributes(attributes, items, multiple = 1) {
  if (!attributes || !Array.isArray(items)) {
    return
  }

  let change = undefined
  attributes.forEach(attribute => {
    change = items.find(item => item.id === attribute.id)
    if (!change) {
      return
    }

    if (
      change.requiredEqualValues !== undefined &&
      change.requiredEqualValues !== null
    ) {
      attribute.requiredEqualValues =
        0 < multiple ? change.requiredEqualValues : !change.requiredEqualValues
    }
  })
}
