import ImageLoader from '@/components/shared/image-loader/ImageLoader'

export default {
  name: 'FormListCollapsedBar',

  components: {
    ImageLoader,
  },

  props: {
    listName: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    selected: {
      type: Array | null,
      required: true,
    },
  },

  methods: {
    emitReturn() {
      this.$emit('return')
    },

    getSelectedOptions() {
      let options = []

      if (this.selected == undefined || this.options == undefined) {
        return options
      }

      this.options.forEach(option => {
        let selected = this.selected.find(x => x.id === option.id)
        if (selected != undefined && selected.quantity > 0) {
          options.push({
            name: option.name,
            quantity: selected.quantity,
          })
        }
      })

      return options
    },
  },
}
