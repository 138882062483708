import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  name: 'FormListFilterRange',

  components: {
    VueSlider,
  },

  props: {
    attribute: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      rangeFrom: 0,
      rangeTo: 0,
      minimum: 0,
      maximum: 0,
    }
  },

  watch: {
    sliderRange: function(range) {
      if (this.rangeFrom >= this.minimum && this.rangeTo <= this.maximum) {
        this.$emit('selected', {
          selected: [range[0], range[1]],
          attribute: this.attribute,
        })
      }
    },
  },

  computed: {
    sliderRange: {
      get() {
        return [this.rangeFrom, this.rangeTo]
      },
      set(value) {
        this.rangeFrom = value[0]
        this.rangeTo = value[1]
      },
    },
  },

  async created() {
    this.rangeFrom = this.attribute.defaults[0]
    this.rangeTo = this.attribute.defaults[1]
    this.setMinMax()
  },

  methods: {
    setMinMax() {
      this.minimum = this.attribute.values[0]
      this.maximum = this.attribute.values[0]

      this.attribute.values.forEach(value => {
        if (value > this.maximum) {
          this.maximum = value
        } else if (value < this.minimum) {
          this.minimum = value
        }
      })
    },

    getUnit() {
      if (this.attribute.unit === undefined || this.attribute.unit === null) {
        return ''
      }

      return ' [' + this.attribute.unit + ']'
    },
  },
}
