import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import Form from '../views/form/Form.vue'
import i18n from '../i18n'
import { SESSION_KEY_BACK_URL } from '../utils/session'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:locale',
    component: {
      template: '<router-view></router-view>',
    },
    beforeEnter: (to, _from, next) => {
      const locale = to.params.locale
      const supportedLocales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(
        ','
      )
      if (!supportedLocales.includes(locale)) {
        return next(process.env.VUE_APP_I18N_LOCALE)
      }

      if (i18n.locale !== locale) {
        i18n.locale = locale
      }

      return next()
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
      },
      {
        path: 'form/:modelId/:currency/:url?',
        name: 'Form',
        component: Form,
        beforeEnter: (to, _from, next) => {
          if (to.params.url === undefined) {
            return next()
          }

          const session = Vue.prototype.$session
          session.set(SESSION_KEY_BACK_URL, to.params.url)

          to.params.url = undefined

          return next(to)
        },
      },
      {
        path: 'order/:modelId/:currency',
        name: 'FormOrder',
        props: {
          isOrder: true,
        },
        component: Form,
      },
    ],
  },
  {
    path: '/form/*',
    redirect: '/pl/form/*',
  },
]

const router = new VueRouter({
  routes,
})

export default router
