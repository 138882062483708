import { render, staticRenderFns } from "./FormSummaryModalFormModalForm.vue?vue&type=template&id=226d9660&scoped=true&"
import script from "./form-summary-modal-form-modal-form.js?vue&type=script&lang=js&"
export * from "./form-summary-modal-form-modal-form.js?vue&type=script&lang=js&"
import style0 from "./form-summary-modal-form-modal-form.scss?vue&type=style&index=0&id=226d9660&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "226d9660",
  null
  
)

export default component.exports