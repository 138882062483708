import './custom.scss'
import Vue from 'vue'
import App from '@/app/App.vue'
import './registerServiceWorker'
import router from './router'
import api from '@/utils/api'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import i18n from './i18n'
import VueSessionStorage from 'vue-sessionstorage'

Vue.config.productionTip = false
Vue.$api = api
Vue.use(VueSessionStorage)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.mixin({
  methods: {
    copyObject(object) {
      return JSON.parse(JSON.stringify(object))
    },
  },
})

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')

Object.defineProperty(Vue.prototype, '$api', {
  get() {
    return api
  },
})
