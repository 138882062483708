import FormMicroserviceAbstract from './form-microservice-abstract'

export default class FormMicroserviceManageWeights extends FormMicroserviceAbstract {
  weightGroupsIndexes = []

  createWeightGroupsIndex(baseModalWeightGroups) {
    for (let i = 0; i < baseModalWeightGroups.length; i++) {
      this.weightGroupsIndexes[baseModalWeightGroups[i].id] = i
    }
  }

  getBestSelectedOptionDistributionBaseOnChangeAndForm(
    components,
    weightGroups,
    form,
    changes
  ) {
    _validateWeightGroupsIndexes(this)

    let bestPossibility = this.getBestSelectedOptionDistributionBaseOnChange(
      components,
      weightGroups,
      changes,
      this
    )
    if (!bestPossibility.isValid) {
      bestPossibility = this.getBestSelectedOptionDistributionBaseOnWholeForm(
        components,
        weightGroups,
        form
      )
    }

    return bestPossibility
  }

  getBestSelectedOptionDistributionBaseOnChange(
    components,
    weightGroups,
    changes
  ) {
    _validateWeightGroupsIndexes(this)

    const possibilities = _getPossibilitiesBaseOnChange(
      changes,
      components,
      weightGroups,
      this
    )

    return _getBestPossibility(possibilities, this)
  }

  getBestSelectedOptionDistributionBaseOnWholeForm(
    components,
    weightGroups,
    form
  ) {
    _validateWeightGroupsIndexes(this)

    const possibilities = _getPossibilitiesBaseOnForm(
      form,
      components,
      weightGroups,
      this
    )

    return _getBestPossibility(possibilities, this)
  }
}

function _validateWeightGroupsIndexes(thisClass) {
  if (0 === thisClass.weightGroupsIndexes.length) {
    throw 'Indexes can not be empty -> createWeightGroupsIndex'
  }
}

function _getPossibilitiesBaseOnChange(
  changes,
  components,
  weightGroups,
  microservice
) {
  let possibilities = [microservice.copyObject(weightGroups)]
  _restoreWeightFromPossibilityForUnselectedOptions(
    changes,
    components,
    possibilities[0],
    microservice
  )
  possibilities = _createPossibilitiesForNewOptions(
    changes,
    components,
    possibilities,
    microservice
  )

  return possibilities
}

function _createPossibilitiesForNewOptions(
  changes,
  components,
  possibilities,
  microservice
) {
  changes.forEach(change => {
    if (0 < change.quantity) {
      const modelOptionData = microservice.findOptionWithComponentName(
        components,
        change.id
      )
      if (undefined === modelOptionData.option) {
        return
      }

      possibilities = _createNewPossibilities(
        microservice.weightGroupsIndexes,
        change.quantity,
        modelOptionData.option,
        possibilities,
        microservice
      )
    }
  })

  return possibilities
}

function _restoreWeightFromPossibilityForUnselectedOptions(
  changes,
  components,
  weightGroupsPossibility,
  microservice
) {
  changes.forEach(change => {
    if (0 > change.quantity) {
      const modelOptionData = microservice.findOptionWithComponentName(
        components,
        change.id
      )
      if (undefined === modelOptionData.option) {
        return
      }

      for (let i = 0; i < weightGroupsPossibility.length; i++) {
        const optionInUseIndex = _getOptionInUseIndex(
          weightGroupsPossibility[i].options,
          change.id
        )
        if (-1 !== optionInUseIndex) {
          _restoreWeightFromGroup(
            optionInUseIndex,
            weightGroupsPossibility[i],
            -1 * change.quantity,
            modelOptionData.option.weightGroups
          )

          if (modelOptionData.option.occupyAllWeightGroups !== true) {
            return
          }
        }
      }
    }
  })
}

function _getOptionInUseIndex(weightGroupOptions, optionId) {
  for (let i = 0; i < weightGroupOptions.length; i++) {
    if (weightGroupOptions[i].id === optionId) {
      return i
    }
  }

  return -1
}

function _restoreWeightFromGroup(
  optionInUseIndex,
  weightGroupPossibility,
  quantityToRestore,
  modelOptionWeightGroups
) {
  weightGroupPossibility.options[optionInUseIndex].quantity -= quantityToRestore
  const weight = modelOptionWeightGroups.find(
    group => group.id === weightGroupPossibility.id
  ).weight
  weightGroupPossibility.weightInUse -= quantityToRestore * weight

  if (0 === weightGroupPossibility.options[optionInUseIndex].quantity) {
    weightGroupPossibility.options.splice(optionInUseIndex, 1)
  }
}

function _getPossibilitiesBaseOnForm(
  form,
  components,
  weightGroups,
  microservice
) {
  let possibilities = [
    _getRestoredWeightGroupsFromOccupatedOptions(weightGroups, microservice),
  ]
  Object.keys(form).forEach(componentName => {
    const modelComponent = components.find(
      component => component.name === componentName
    )
    if (
      undefined === modelComponent ||
      undefined === modelComponent.list ||
      !Array.isArray(modelComponent.list)
    ) {
      return
    }

    form[componentName].forEach(selectedOption => {
      const modelOption = modelComponent.list.find(
        option => option.id === selectedOption.id
      )
      if (undefined === modelOption || undefined === modelOption.weightGroups) {
        return
      }
      possibilities = _createNewPossibilities(
        microservice.weightGroupsIndexes,
        selectedOption.quantity,
        modelOption,
        possibilities,
        microservice
      )
    })
  })

  return possibilities
}

function _getRestoredWeightGroupsFromOccupatedOptions(
  weightGroups,
  microservice
) {
  const restoredWeightGroups = microservice.copyObject(weightGroups)
  restoredWeightGroups.forEach(group => {
    group.weightInUse = 0
    group.options = []
  })

  return restoredWeightGroups
}

function _initBestPossibility(possibility) {
  const score = _getWeightGroupsScore(possibility)
  const bestPossibility = {
    points: score.points,
    possibility: possibility,
    isValid: score.isValid,
  }

  return bestPossibility
}

function _initBestValidPossibility(bestPossibility) {
  const bestValidPossibility = bestPossibility
  if (!bestValidPossibility.isValid) {
    bestValidPossibility.points = 0
  }

  return bestValidPossibility
}

function _getBestPossibility(possibilities, microservice) {
  let bestPossibility = _initBestPossibility(possibilities[0])
  let bestValidPossibility = _initBestValidPossibility(
    microservice.copyObject(bestPossibility)
  )

  for (let i = 1; i < possibilities.length; i++) {
    const score = _getWeightGroupsScore(possibilities[i])
    if (score.isValid && score.points >= bestValidPossibility.points) {
      bestValidPossibility.isValid = true
      bestValidPossibility.points = score.points
      bestValidPossibility.possibility = possibilities[i]
    } else if (
      !bestValidPossibility.isValid &&
      score.points > bestPossibility.points
    ) {
      bestPossibility.points = score.points
      bestPossibility.possibility = possibilities[i]
    }
  }

  if (bestValidPossibility.isValid) {
    return bestValidPossibility
  }

  return bestPossibility
}

function _getWeightGroupsScore(possibility) {
  const score = {
    points: 0,
    isValid: true,
  }
  possibility.forEach(weightGroup => {
    if (
      undefined !== weightGroup.isUnlimited &&
      true === weightGroup.isUnlimited
    ) {
      score.points += 100
      return
    }

    let tmpPoints = 0
    if (0 === weightGroup.weight && 0 < weightGroup.weightInUse) {
      tmpPoints = -100
    } else if (0 !== weightGroup.weight) {
      tmpPoints = 100 - (weightGroup.weightInUse / weightGroup.weight) * 100
    }
    if (0 > tmpPoints) {
      score.isValid = false
    }

    score.points += tmpPoints
  })

  return score
}

function _createPossibilities(
  weightGroupsIndexes,
  quantity,
  possibility,
  possibilities,
  modelOption,
  microservice,
  weightGroupStarterPosition = 0
) {
  for (
    let i = weightGroupStarterPosition;
    i < modelOption.weightGroups.length;
    i++
  ) {
    const index = weightGroupsIndexes[modelOption.weightGroups[i].id]
    for (let j = 1; j <= quantity; j++) {
      const newPossibility = microservice.copyObject(possibility)
      _addWeightToGroup(
        modelOption.weightGroups[i].weight,
        modelOption.id,
        j,
        newPossibility[index]
      )
      if (_isLastQuantityMove(j, quantity)) {
        possibilities.push(newPossibility)
      } else if (
        _isThisNotTheLastOneModelOptionGroup(i, modelOption.weightGroups)
      ) {
        _createPossibilities(
          weightGroupsIndexes,
          quantity - j,
          newPossibility,
          possibilities,
          modelOption,
          microservice,
          i + 1
        )
      }
    }
  }
}

function _getPossibilityOccupyAllWeightGroups(
  weightGroupsIndexes,
  quantity,
  possibility,
  modelOption,
  microservice
) {
  const newPossibility = microservice.copyObject(possibility)
  for (let i = 0; i < modelOption.weightGroups.length; i++) {
    const index = weightGroupsIndexes[modelOption.weightGroups[i].id]
    _addWeightToGroup(
      modelOption.weightGroups[i].weight,
      modelOption.id,
      quantity,
      newPossibility[index]
    )
  }

  return newPossibility
}

function _createNewPossibilities(
  weightGroupsIndexes,
  quantity,
  modelOption,
  possibilities,
  microservice
) {
  const newPossibilities = []
  if (modelOption.occupyAllWeightGroups === true) {
    possibilities.forEach(possibility => {
      const newPossibility = _getPossibilityOccupyAllWeightGroups(
        weightGroupsIndexes,
        quantity,
        possibility,
        modelOption,
        microservice
      )
      newPossibilities.push(newPossibility)
    })
  } else {
    possibilities.forEach(possibility => {
      _createPossibilities(
        weightGroupsIndexes,
        quantity,
        possibility,
        newPossibilities,
        modelOption,
        microservice
      )
    })
  }

  if (0 < newPossibilities.length) {
    return newPossibilities
  }

  return possibilities
}

function _isLastQuantityMove(move, quantity) {
  return move === quantity
}

function _isThisNotTheLastOneModelOptionGroup(
  groupIndex,
  modelOptionWeightGroups
) {
  return groupIndex + 1 !== modelOptionWeightGroups.length
}

function _addWeightToGroup(
  optionWeightUseInGroup,
  modelOptionId,
  quantity,
  weightGroupPossibility
) {
  const weightGroupPossibilityOption = weightGroupPossibility.options.find(
    option => option.id === modelOptionId
  )
  if (undefined === weightGroupPossibilityOption) {
    _createNewWeightGroupOption(
      optionWeightUseInGroup,
      modelOptionId,
      quantity,
      weightGroupPossibility
    )

    return
  }

  _addToExistingWeightGroupOption(
    optionWeightUseInGroup,
    quantity,
    weightGroupPossibilityOption,
    weightGroupPossibility
  )
}

function _createNewWeightGroupOption(
  optionWeightUseInGroup,
  optionId,
  quantity,
  weighGroupsPossibility
) {
  weighGroupsPossibility.options.push({
    id: optionId,
    quantity: quantity,
  })
  weighGroupsPossibility.weightInUse += optionWeightUseInGroup * quantity
}

function _addToExistingWeightGroupOption(
  optionWeightUseInGroup,
  quantity,
  weightGroupPossibilityOption,
  weightGroupPossibility
) {
  weightGroupPossibilityOption.quantity += quantity
  weightGroupPossibility.weightInUse += optionWeightUseInGroup * quantity
}
