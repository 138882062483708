import FormChooseCountableMultipleOptionList from '@/components/form/default/form-choose-countable-multiple-option-list/FormChooseCountableMultipleOptionList'
import FormChooseCountableSingleOptionList from '@/components/form/default/form-choose-countable-single-option-list/FormChooseCountableSingleOptionList'
import FormChooseMultipleOptionList from '@/components/form/default/form-choose-multiple-option-list/FormChooseMultipleOptionList'
import FormChooseSingleOptionList from '@/components/form/default/form-choose-single-option-list/FormChooseSingleOptionList'
import FormListInformationPanel from '@/components/form/default/form-list-information-panel/FormListInformationPanel'
import FormOptionRequirementsModal from '@/components/form/default/form-option-requirements-modal/FormOptionRequirementsModal'
import FormSolutionsModal from '@/components/form/default/form-solutions-modal/FormSolutionsModal'
import FormSummaryModalFormModal from '@/components/form/default/form-summary-modal/form-summary-modal-form-modal/FormSummaryModalFormModal'
import FormSummaryModal from '@/components/form/default/form-summary-modal/FormSummaryModal'
import FormSummaryPanel from '@/components/form/default/form-summary-panel/FormSummaryPanel'
import LoadingOverlay from '@/components/shared/loading-overlay/LoadingOverlay'
import { headerRxjsHelper } from '@/helpers/header-rxjs-helper.js'
import { Decimal } from 'decimal.js'
import Vue from 'vue'
import FormSummaryBar from '@/components/form/default/form-summary-bar/FormSummaryBar'
import FormSummaryModalFormModalService from '@/components/form/default/form-summary-modal/form-summary-modal-form-modal/form-summary-modal-form-modal-service'
import FormService from './form-service'

export const SINGLE_OPTION_LIST = 1
export const MULTIPLE_OPTION_LIST = 2
export const COUNTABLE_SINGLE_OPTION_LIST = 3
export const COUNTABLE_MULTIPLE_OPTION_LIST = 4
const REGEX_PRICE = /\B(?=(\d{3})+(?!\d))/g

export default {
  name: 'Form',

  components: {
    FormChooseSingleOptionList,
    FormChooseMultipleOptionList,
    FormChooseCountableMultipleOptionList,
    FormChooseCountableSingleOptionList,
    FormOptionRequirementsModal,
    LoadingOverlay,
    FormSolutionsModal,
    FormSummaryModal,
    FormSummaryModalFormModal,
    FormSummaryPanel,
    FormSummaryBar,
    FormListInformationPanel,
  },

  props: {
    isOrder: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      contactData: undefined,
      summaryModalsService: new FormSummaryModalFormModalService(this),
      renderErrorNotificationBox: true,
      formService: new FormService(),
      totalPrice: {
        net: 0,
        gross: 0,
      },
      loadingOverlay: {
        error: false,
        isLoading: true,
        msg: 'shared.loading-configurator',
      },
      isLoading: true,
      responseModalData: null,
      solutionsModalData: [],
      summaryModalData: {},
      summaryModalFormValues: {},
      vat: 0.23,
      modelInformationData: {
        current: null,
        old: null,
      },
      form: {
        current: {},
        old: {},
        invalidFeedbacks: {},
        submittedLists: {},
        requirements: undefined,
      },
      workers: {
        invalidFeedbacks: [],
      },
      innerWidth: window.innerWidth,
      modelId: this.$route.params.modelId,
      currency: this.$route.params.currency,
      SINGLE_OPTION_LIST: SINGLE_OPTION_LIST,
      MULTIPLE_OPTION_LIST: MULTIPLE_OPTION_LIST,
      COUNTABLE_SINGLE_OPTION_LIST: COUNTABLE_SINGLE_OPTION_LIST,
      COUNTABLE_MULTIPLE_OPTION_LIST: COUNTABLE_MULTIPLE_OPTION_LIST,
    }
  },

  watch: {
    form: {
      deep: true,
      handler() {
        this.totalPrice = this.formService.computeTotalPrice(
          this.form.current,
          this.modelInformationData.current.components,
          this.modelInformationData.current.price,
          this.vat
        )
        this.summaryModalsService.updateModalsDataGuard(
          this.modalsData,
          this.form.current,
          this.form.invalidFeedbacks
        )

        headerRxjsHelper.setModalsData(this.modalsData)
      },
    },
  },

  mounted() {
    window.addEventListener('resize', () => {
      this.innerWidth = window.innerWidth
    })

    this.contactDataSubscription = headerRxjsHelper
      .getContactData()
      .subscribe(contactData => {
        this.contactData = contactData
      })

    this.currentModalSubscription = headerRxjsHelper
      .getCurrentModal()
      .subscribe(currentModal => {
        if (!currentModal) {
          return
        }
        this.setSummaryModalData(currentModal)
        this.summaryModalsService.showModal(currentModal)
      })
  },

  async created() {
    let response
    if (!this.isOrder) {
      response = await Vue.$api.get(this.modelId, this.currency)
    } else {
      response = await Vue.$api.getOrder(this.modelId, this.currency)
    }

    if (200 !== response.status) {
      this.loadingOverlay.error = true
      this.loadingOverlay.msg =
        response.status === 204 ? 'shared.not-found' : 'shared.unexpected-error'

      return
    }

    this.modelInformationData.current = response.data
    this.modalsData = this.summaryModalsService.modalsData

    headerRxjsHelper.setTitle(this.modelInformationData.current.name)
    headerRxjsHelper.setModalsData(this.modalsData)

    this.prepDataOnStart()

    const defaultSelection = this.formService.getDefaultFormSelectionObject(
      this.modelInformationData.current.components
    )

    this.form.current = defaultSelection.form

    this.formService.onSelect(
      this.modelInformationData.current,
      {}, // old form
      defaultSelection.changes
    )

    this.createWeightGroupsIndexes()
    this.findAndChooseBestSelectedOptionDistributionBaseOnWholeForm()

    this.validateForm()
    this.findSolutionsIfNeeded()

    this.copyFormAndModelInformation()

    this.loadingOverlay.isLoading = false

    this.totalPrice = this.formService.computeTotalPrice(
      this.form.current,
      this.modelInformationData.current.components,
      this.modelInformationData.current.price,
      this.vat
    )
  },

  beforeDestroy() {
    this.contactDataSubscription.unsubscribe()
    this.currentModalSubscription.unsubscribe()
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.$bvModal.show('form-summary-modal')
    },

    isInvalidFeebacksInComponent(componentName) {
      return (
        this.form.invalidFeedbacks[componentName] !== undefined &&
        this.form.invalidFeedbacks[componentName].length > 0
      )
    },

    copyFormAndModelInformation() {
      this.form.old = this.copyObject(this.form.current)
      this.modelInformationData.old = this.copyObject(
        this.modelInformationData.current
      )
    },

    validateForm() {
      this.form.invalidFeedbacks = this.formService.validate(
        this.modelInformationData.current,
        this.form.current
      )
    },

    onReset() {
      this.$router.go(0)
    },

    onLastListInput(input, componentName) {
      this.breakFindingSolutions()

      const changes = this.formService.getFormChanges(this.form.old, {
        componentName: componentName,
        options: input,
      })

      this.formService.onSelect(
        this.modelInformationData.current,
        this.form.old,
        changes
      )

      const requirements = this.formService.getRequirementsPriorityByChanges(
        this.modelInformationData.current.components,
        this.form.current,
        changes
      )

      // + this function sets requirements data if requirements exists
      this.showModalIfRequirementsWereFound(requirements)
      if (undefined === this.form.requirements) {
        this.findAndChooseBestSelectedOptionDistributionBaseOnChange(changes)
        this.copyFormAndModelInformation()
        this.validateForm()
        this.findSolutionsIfNeeded()
      }
    },

    onModalRequirementsEmit(formChanges) {
      this.breakFindingSolutions()

      if (!formChanges) {
        this.resetFormAndModelInformationToPrevSelection()
        this.validateForm()
      } else {
        this.addChangesToForm(formChanges)
      }

      this.findSolutionsIfNeeded()

      this.form.requirements = undefined
    },

    addChangesToForm(changes) {
      this.formService.onSelect(
        this.modelInformationData.current,
        this.form.old,
        changes
      )
      this.formService.addApprovedRequirementsToForm(
        this.modelInformationData.current.components,
        this.form.current,
        changes
      )
      this.findAndChooseBestSelectedOptionDistributionBaseOnChange(changes)
      this.copyFormAndModelInformation()
      this.validateForm()
    },

    resetFormAndModelInformationToPrevSelection() {
      this.form.current = this.copyObject(this.form.old)
      this.modelInformationData.current = this.copyObject(
        this.modelInformationData.old
      )
    },

    showModalIfRequirementsWereFound(requirements) {
      if (requirements === undefined) {
        return
      }

      Object.keys(requirements).some(componentName => {
        if (this.form.requirements !== undefined) {
          return true
        }

        const componentsRequiremetnsObject = requirements[componentName]
        Object.keys(componentsRequiremetnsObject).some(optionId => {
          const optionRequirements = componentsRequiremetnsObject[optionId]
          if (optionRequirements.length > 0) {
            this.form.requirements = requirements
            this.$bvModal.show('requirements-modal')

            return true
          }
        })
      })
    },

    findAndChooseBestSelectedOptionDistributionBaseOnWholeForm() {
      this.formService.findAndChooseBestSelectedOptionDistributionBaseOnWholeForm(
        this.modelInformationData.current,
        this.form.current
      )
    },

    findAndChooseBestSelectedOptionDistributionBaseOnChange(changes) {
      this.formService.findAndChooseBestSelectedOptionDistributionBaseOnChange(
        this.modelInformationData.current,
        this.form.current,
        changes
      )
    },

    createWeightGroupsIndexes() {
      this.formService.createWeightGroupsIndexes(
        this.modelInformationData.current.weightGroups
      )
    },

    prepDataOnStart() {
      this.formService.prepDataOnStart(this.modelInformationData.current)
    },

    findSolutionsIfNeeded() {
      this.formService.runFindingSolutions(
        this.modelInformationData.current,
        this.form.current,
        this.form.invalidFeedbacks,
        this.workers.invalidFeedbacks,
        this.refreshErrorNotificationBox
      )
    },

    refreshErrorNotificationBox() {
      // Hack to refresh FormErrorNotificationBox
      this.renderErrorNotificationBox = false
      this.$nextTick(() => {
        this.renderErrorNotificationBox = true
      })
    },

    breakFindingSolutions() {
      this.formService.closeWorkers(this.workers.invalidFeedbacks)
    },

    openSolutionsModal(invalidFeedback) {
      if (
        Array.isArray(invalidFeedback.solutions) &&
        0 < invalidFeedback.solutions.length
      ) {
        this.solutionsModalData = invalidFeedback.solutions
        this.$bvModal.show('solutions-modal')
      }
    },

    setSummaryModalData(modalData) {
      this.summaryModalData = modalData
    },

    onSummaryModalFormValuesChange(values) {
      this.summaryModalFormValues = values
    },

    getTotalPriceNet() {
      return new Decimal(this.totalPrice.net)
        .toFixed(2)
        .toString()
        .replace(REGEX_PRICE, ' ')
        .replace('.', ',')
    },

    getTotalPriceGross() {
      return new Decimal(this.totalPrice.gross)
        .toFixed(2)
        .toString()
        .replace(REGEX_PRICE, ' ')
        .replace('.', ',')
    },

    setListSubmitted(value) {
      this.$set(this.form.submittedLists, value.name, value.isSubmitted)
    },
  },
}
