import FormChooseSingleOptionList from '@/components/form/default/form-choose-single-option-list/FormChooseSingleOptionList'

export default {
  name: 'FormSolutionsModal',

  components: {
    FormChooseSingleOptionList,
  },

  props: {
    solutions: {
      type: Array,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selected: [],
    }
  },

  methods: {
    closeModal() {
      this.selected = []
      this.$bvModal.hide('solutions-modal')
    },

    submitModal() {
      this.$emit('submit', this.selected)
      this.closeModal()
    },

    isSolutionSelected() {
      return (
        this.selected.length === 1 &&
        this.selected[0].id &&
        this.selected[0].quantity === 1
      )
    },
  },
}
