import Multiselect from 'vue-multiselect'

export default {
  name: 'FormListFilterSelectMultiple',

  components: {
    Multiselect,
  },

  props: {
    attribute: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selected: [],
    }
  },

  async created() {
    if (this.attribute.defaults) {
      this.selected = this.attribute.defaults
      this.onSelectChange()
    }
  },

  methods: {
    onSelectChange() {
      this.$emit('selected', {
        selected: this.selected,
        attribute: this.attribute,
      })
    },

    getSelectedFilters() {
      if (this.selected.length == 1) {
        return this.selected[0]
      }

      return this.$t('form.filter-bar.count-selected-filters', {
        count: this.selected.length,
      })
    },

    isOptionChecked(option) {
      return this.selected.includes(option)
    },
  },
}
