import FormOptionDetailsCollapse, {
  COMP_TYPE_ON_SELECT,
  COMP_TYPE_REQUIREMENTS,
  ITER_TYPE_ONE_TIME,
  ITER_TYPE_EACH_TIME,
} from '@/components/form/default/form-option-details-box/form-option-details-collapse/FormOptionDetailsCollapse'
import FormOptionDetailsCollapseAttributes from '@/components/form/default/form-option-details-box/form-option-details-collapse-attributes/FormOptionDetailsCollapseAttributes'

export default {
  name: 'FormOptionDetailsBoxInfo',

  components: {
    FormOptionDetailsCollapse,
    FormOptionDetailsCollapseAttributes,
  },

  props: {
    collapsed: {
      type: Array,
      require: true,
    },

    option: {
      type: Object,
      require: true,
    },
  },

  data() {
    return {
      COMP_TYPE_REQUIREMENTS,
      COMP_TYPE_ON_SELECT,
      ITER_TYPE_EACH_TIME,
      ITER_TYPE_ONE_TIME,
    }
  },

  methods: {
    getWeight() {
      if (!this.option.weight) {
        return 1
      }
      return this.option.weight
    },

    isOnSelectEachTime() {
      if (
        this.option.onSelect !== undefined &&
        this.option.onSelect.eachTime !== undefined
      ) {
        return this.isContentExists(this.option.onSelect.eachTime)
      }
    },

    isOnSelectOneTime() {
      if (
        this.option.onSelect !== undefined &&
        this.option.onSelect.oneTime !== undefined
      ) {
        return this.isContentExists(this.option.onSelect.oneTime)
      }
    },

    isRequirementsEachTime() {
      if (
        this.option.requirements !== undefined &&
        this.option.requirements.eachTime !== undefined
      ) {
        return this.isContentExists(this.option.requirements.eachTime)
      }
    },

    isRequirementsOneTime() {
      if (
        this.option.requirements !== undefined &&
        this.option.requirements.oneTime !== undefined
      ) {
        return this.isContentExists(this.option.requirements.oneTime)
      }
    },

    isContentExists(data) {
      if (data.components !== undefined) {
        return data.components.length > 0
      }
      if (data.weightGroups !== undefined) {
        return data.weightGroups.length > 0
      }
      if (data.componentsFromList !== undefined) {
        return data.componentsFromList.length > 0
      }
    },

    isWeightNotDefault() {
      return this.option.weight
    },

    isAttributes() {
      return (
        Array.isArray(this.option.attributes) &&
        0 < this.option.attributes.length
      )
    },
  },
}
