export default {
  name: 'LanguageSwitcher',

  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
    }
  },

  methods: {
    getLanguageBackground() {
      let url = require(`@/assets/images/locales/${this.$i18n.locale}.png`)
      return `--lang-bg: url('${url}');`
    },

    switchLocale(locale) {
      if (this.isSelected(locale)) {
        return
      }

      this.$i18n.locale = locale
      const to = this.$router.resolve({ params: { locale } })
      this.$router.push(to.location)

      window.location.reload()
    },

    isSelected(locale) {
      return this.$i18n.locale === locale
    },
  },
}
