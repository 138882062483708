import FormMicroserviceAbstract from './form-microservice-abstract'

export default class FormMicroserviceRequirements extends FormMicroserviceAbstract {
  getRequirementsPriorityByChanges(components, form, changes) {
    const requirements = this.getRequirements(components, form)
    this.priorityRequirementsByChanges(components, changes, requirements)

    return requirements
  }

  getRequirements(components, form) {
    const requirementsObject = {}

    Object.keys(form).forEach(index => {
      form[index].forEach(formObject => {
        const optionWithComponentName = this.findOptionWithComponentName(
          components,
          formObject.id
        )

        if (!optionWithComponentName.option.requirements) {
          return
        }

        _initRequirementsObject(
          requirementsObject,
          optionWithComponentName.componentName,
          optionWithComponentName.option.id
        )

        _addRequirements(
          form,
          optionWithComponentName.option,
          formObject.quantity,
          requirementsObject[optionWithComponentName.componentName][
            optionWithComponentName.option.id
          ]
        )
      })
    })

    return requirementsObject
  }

  priorityRequirementsByChanges(components, changes, requirementsObject) {
    changes.forEach(changeObject => {
      Object.keys(requirementsObject).some(componentName => {
        Object.keys(requirementsObject[componentName]).forEach(optionId => {
          if (parseInt(optionId) === changeObject.id) {
            return
          }

          const changeObjectComponentName = this.findOptionWithComponentName(
            components,
            changeObject.id
          ).componentName

          requirementsObject[componentName][optionId].some(requirement => {
            _priorityRequirementsByChange(
              this,
              components,
              requirement,
              componentName,
              optionId,
              changeObject,
              changeObjectComponentName,
              requirementsObject
            )
          })
        })
      })
    })
  }

  addApprovedRequirementsToForm(components, form, approvedChanges) {
    approvedChanges.forEach(change => {
      // Option always exists (if not: there is bug before this function)
      const componentName = this.findOptionWithComponentName(
        components,
        change.id
      ).componentName

      if (!form[componentName]) {
        form[componentName] = []
        form[componentName].push(change)

        return
      }

      const changedFormObject = _changeExistingFormObjectBasedOnChange(
        form[componentName],
        change
      )
      if (changedFormObject) {
        form[componentName] = changedFormObject

        return
      }

      form[componentName].push(change)
    })
  }
}

function _initRequirementsObject(requirementsObject, componentName, optionId) {
  if (!requirementsObject[componentName]) {
    requirementsObject[componentName] = {}
  }
  if (!requirementsObject[componentName][optionId]) {
    requirementsObject[componentName][optionId] = []
  }
}

function _changeExistingFormObjectBasedOnChange(formObject, approvedChange) {
  for (let i = 0; i < formObject.length; i++) {
    if (formObject[i].id === approvedChange.id) {
      formObject[i].quantity += approvedChange.quantity
      if (0 === formObject[i].quantity) {
        formObject = formObject.filter(item => item.id !== formObject[i].id)
      }

      return formObject
    }
  }

  return false
}

function _addRequirements(
  form,
  option,
  formObjectQuantity,
  requirementsObject
) {
  if (option.requirements.oneTime) {
    _addRequirementsOptions(
      form,
      option.requirements.oneTime,
      requirementsObject
    )
  }

  if (option.requirements.eachTime) {
    _addRequirementsOptions(
      form,
      option.requirements.eachTime,
      requirementsObject,
      formObjectQuantity
    )
  }
}

function _addRequirementsOptions(
  form,
  requirements,
  requirementsObject,
  formObjectQuantity = 1
) {
  if (requirements.components && Array.isArray(requirements.components)) {
    _addRequirementsOptionsComponents(
      form,
      requirements.components,
      formObjectQuantity,
      requirementsObject
    )
  }

  if (
    requirements.componentsFromList &&
    Array.isArray(requirements.componentsFromList)
  ) {
    _addRequirementsOptionsComponentsFromList(
      form,
      requirements.componentsFromList,
      formObjectQuantity,
      requirementsObject
    )
  }
}

function _addRequirementsOptionsComponents(
  form,
  requiredComponents,
  formObjectQuantity,
  requirementsObject
) {
  requiredComponents.forEach(requiredComponent => {
    const formObject = _findFormObjectById(form, requiredComponent.id)

    _addRequirementsOptionsQuantity(
      requiredComponent,
      formObject ? formObject.quantity : 0,
      {
        id: requiredComponent.id,
        name: requiredComponent.name,
        quantity: undefined,
      },
      formObjectQuantity,
      requirementsObject
    )
  })
}

function _addRequirementsOptionsComponentsFromList(
  form,
  requiredComponentsFromList,
  formObjectQuantity,
  requirementsObject
) {
  requiredComponentsFromList.forEach(requiredFromList => {
    let countQuantity = 0
    requiredFromList.list.forEach(requiredComponent => {
      const formObject = _findFormObjectById(form, requiredComponent.id)
      if (formObject) {
        countQuantity += formObject.quantity
      }
    })

    _addRequirementsOptionsQuantity(
      requiredFromList,
      countQuantity,
      {
        list: requiredFromList.list,
        quantity: undefined,
      },
      formObjectQuantity,
      requirementsObject
    )
  })
}

function _addRequirementsOptionsQuantity(
  required,
  optionQuantity,
  prepRequirementObjetc,
  formObjectQuantity,
  requirementsObject
) {
  if (
    _isRequiredQuantityInvalid(
      required.quantity,
      optionQuantity,
      formObjectQuantity
    )
  ) {
    prepRequirementObjetc.quantity =
      required.quantity * formObjectQuantity - optionQuantity
  } else if (_isMinQuantityInvalid(required.minQuantity, optionQuantity)) {
    prepRequirementObjetc.quantity = required.minQuantity - optionQuantity
  } else if (_isMaxQuantityInvalid(required.maxQuantity, optionQuantity)) {
    prepRequirementObjetc.quantity = required.maxQuantity - optionQuantity
  }

  if (prepRequirementObjetc.quantity) {
    const reqObject = _getRequirementsObjectItem(
      requirementsObject,
      prepRequirementObjetc
    )
    if (!reqObject) {
      requirementsObject.push(prepRequirementObjetc)

      return
    }

    reqObject.quantity += prepRequirementObjetc.quantity
  }
}

function _isRequiredQuantityInvalid(
  requiredQuantity,
  optionQuantity,
  formObjectQuantity
) {
  return (
    undefined !== requiredQuantity &&
    optionQuantity !== requiredQuantity * formObjectQuantity
  )
}

function _isMinQuantityInvalid(requiredMinQuantity, optionQuantity) {
  return (
    undefined !== requiredMinQuantity && optionQuantity < requiredMinQuantity
  )
}

function _isMaxQuantityInvalid(requiredMaxQuantity, optionQuantity) {
  return (
    undefined !== requiredMaxQuantity && optionQuantity > requiredMaxQuantity
  )
}

function _findFormObjectById(form, objectId) {
  let formObject = undefined
  Object.keys(form).some(index => {
    formObject = form[index].find(item => item.id === objectId)
    if (formObject) {
      return true
    }
  })

  return formObject
}

function _getRequirementsObjectItem(requirementsObject, prepRequirementObjetc) {
  if (prepRequirementObjetc.list) {
    return requirementsObject.find(
      item =>
        JSON.stringify(item.list) === JSON.stringify(prepRequirementObjetc.list)
    )
  } else if (prepRequirementObjetc.id) {
    return requirementsObject.find(item => item.id === prepRequirementObjetc.id)
  }

  return undefined
}

function _priorityRequirementsByChange(
  parentThis,
  components,
  requirement,
  componentName,
  optionId,
  changeObject,
  changeObjectComponentName,
  requirementsObject
) {
  if (_isRequirementBlockingIncomingChangeById(requirement, changeObject)) {
    // remove blocking requirements
    requirementsObject[componentName][optionId] = []

    // init requirementsObject[option.componentName][change.id]
    _initRequirementsObject(
      requirementsObject,
      changeObjectComponentName,
      changeObject.id
    )

    // add new requirement
    const optionToRemove = parentThis.findOptionWithComponentName(
      components,
      parseInt(optionId)
    )
    requirementsObject[changeObjectComponentName][changeObject.id].push({
      id: optionToRemove.option.id,
      name: optionToRemove.option.name,
      quantity: -1,
    })

    return true
  }
}

function _isRequirementBlockingIncomingChangeById(
  requirementObject,
  changeObject
) {
  return (
    undefined !== requirementObject.id &&
    requirementObject.id === changeObject.id &&
    0 === changeObject.quantity + requirementObject.quantity
  )
}
